import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo2.propTypes = {
  sx: PropTypes.object
};

export default function Logo2({ sx }) {
  // return <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />;
  return <Box component="img" src="/static/RAF-Logo-2.png" sx={{ width: 240, height: 85, ...sx }} />;
}
