import http from '../../http-common';

class PRUploaderService {
  uploadBrokerage(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'application/json',
      onUploadProgress,
    };

    return http.post("/api/profitabilityReport/uploadBrokerage", formData, config);
  }

  uploadBCC(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'application/json',
      onUploadProgress,
    };

    return http.post("/api/profitabilityReport/uploadBCC", formData, config);
  }

  uploadStaff(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'application/json',
      onUploadProgress,
    };

    return http.post("/api/profitabilityReport/uploadStaff", formData, config);
  }

  uploadSP(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'application/json',
      onUploadProgress,
    };

    return http.post("/api/profitabilityReport/uploadSP", formData, config);
  }
}

export default new PRUploaderService();
