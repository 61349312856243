import * as Yup from 'yup';
import React, { useEffect, useState, forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Autocomplete,
  Paper,
  Snackbar,
  Alert,
  Box
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
//
import SPProfitabilityReportService from '../../services/billing-and-invoicing/SPProfitabilityReport.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SPProfitabilityReport() {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [arrivalDate, setArrivalDate] = useState([null, null]);
  const [arrivalDateFrom, setArrivalDateFrom] = useState('');
  const [arrivalDateTo, setArrivalDateTo] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [customersListLoaded, setCustomersListLoaded] = useState(false);

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    SPProfitabilityReportService.getCustomerForSPPR()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setCustomersList(data.responseData);
        setCustomersListLoaded(true);
      })
      .catch((error) => {
        showAlert('error', error.message);
      });
  }, []);

  const RegisterSchema = Yup.object().shape({
    customer: Yup.string().nullable().required('Customer is required'),
    arrivalDateFrom: Yup.string().required('Arrival date from is required'),
    arrivalDateTo: Yup.string().required('Arrival date to is required')
  });

  const formik = useFormik({
    initialValues: {
      customer: '',
      arrivalDateFrom: '',
      arrivalDateTo: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      generateReport();
      setSubmitting(false);
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setSubmitting
  } = formik;

  const generateReport = () => {
    if (customer === null) {
      showAlert('error', 'Invalid customer');
    } else {
      const data = {
        companyName: customer.companyName,
        arrivalDateFrom: moment(arrivalDateFrom).format('YYYY-MM-DD'),
        arrivalDateTo: moment(arrivalDateTo).format('YYYY-MM-DD')
      };

      let urlFinal = '';

      SPProfitabilityReportService.generateSPPR(data)
        .then((response) => {
          return response.data;
        })
        .then(async (data) => {
          console.log(data);

          if (data.responseCode === 200) {
            const outputFilename = `SP Profitability Report.xls`;

            const byteCharacters = atob(data.responseData.excel);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            console.log(byteArray);

            const blob = await new Blob([byteArray], { type: 'application/excel' });
            const url = await window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();

            showAlert('success', data.responseMessage);
          } else {
            showAlert('error', data.responseMessage);
          }
        })
        .catch((error) => {
          showAlert('error', error.message);
        });
    }
  };

  return (
    <Page title={`SP Profitability Report | ${process.env.REACT_APP_APP_NAME}`}>
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={hideAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>
      </Container>
      <Container maxWidth="sm">
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                <Autocomplete
                  freeSolo
                  fullWidth
                  disabled={!customersListLoaded}
                  options={customersList}
                  getOptionLabel={(option) => option.companyName}
                  onChange={(e, value) => {
                    console.log(value);
                    setCustomer(value);
                    setFieldValue('customer', value.companyName ? value.companyName : '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer"
                      name="customer"
                      {...getFieldProps('customer')}
                      error={Boolean(touched.customer && errors.customer)}
                      helperText={touched.customer && errors.customer}
                    />
                  )}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    startText="Arrival Date From"
                    endText="Arrival Date To"
                    value={arrivalDate}
                    onChange={(value) => {
                      console.log(value);
                      setArrivalDate(value);
                      setArrivalDateFrom(value[0] === null ? '' : value[0]);
                      setArrivalDateTo(value[1] === null ? '' : value[1]);
                      setFieldValue('arrivalDateFrom', value[0] === null ? '' : value[0]);
                      setFieldValue('arrivalDateTo', value[1] === null ? '' : value[1]);
                    }}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                          <TextField
                            {...startProps}
                            fullWidth
                            name="arrivalDateFrom"
                            {...getFieldProps('arrivalDateFrom')}
                            error={Boolean(touched.arrivalDateFrom && errors.arrivalDateFrom)}
                            helperText={touched.arrivalDateFrom && errors.arrivalDateFrom}
                          />
                          <TextField
                            {...endProps}
                            fullWidth
                            name="arrivalDateTo"
                            {...getFieldProps('arrivalDateTo')}
                            error={Boolean(touched.arrivalDateTo && errors.arrivalDateTo)}
                            helperText={touched.arrivalDateTo && errors.arrivalDateTo}
                          />
                        </Stack>
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider>
              </Stack>

              <LoadingButton
                size="large"
                type="submit"
                variant="outlined"
                loading={isSubmitting}
                sx={{ maxWidth: '200px', alignSelf: 'center' }}
              >
                Generate Report
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
