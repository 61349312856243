import http from '../../http-common';

class DomesticAttachmentService {
  getCustomerForDomesticAttachment() {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.get('/api/billingAndInvoicing/getCustomerForInvoice', config);
  }


  domesticAttachment(data) {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.post('/api/billingAndInvoicing/domesticAttachment', data, config);
  }

  searchInvoice(data) {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.post('/api/billingAndInvoicing/searchDomesticAttachmentInvoice', data, config);
  }
}

export default new DomesticAttachmentService();
