import React, { useEffect, useState, forwardRef } from 'react';
import { filter } from 'lodash';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box,
  Stack,
  Snackbar,
  Alert,
  Link
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/_dashboard/details-uploader';
//
import CustomerUploaderService from '../../services/accounts-detail-uploader/CustomerUploader.service';
import UtilsService from '../../services/accounts-detail-uploader/Utils.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const moment = require('moment');

const TABLE_HEAD = [
  { id: 'id_', label: 'ID', alignRight: false },
  { id: 'startTime', label: 'START TIME', alignRight: false },
  { id: 'completionTime', label: 'COMPLETION TIME', alignRight: false },
  { id: 'email', label: 'EMAIL', alignRight: false },
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'accountNo', label: 'ACCOUNT NUMBER', alignRight: false },
  { id: 'modePayment', label: 'MODE PAYMENT', alignRight: false },
  { id: 'companyName', label: 'COMPANY NAME', alignRight: false },
  { id: 'address', label: 'ADDRESS', alignRight: false },
  { id: 'tin', label: 'TIN', alignRight: false },
  { id: 'contactPerson', label: 'CONTACT PERSON', alignRight: false },
  { id: 'position', label: 'POSITION', alignRight: false },
  { id: 'contactNo', label: 'CONTACT NUMBER', alignRight: false },
  { id: 'emailAddress', label: 'EMAIL ADDRESS', alignRight: false },
  { id: 'billingContactPerson', label: 'BILLING CONTACT PERSON', alignRight: false },
  { id: 'position2', label: 'POSITION2', alignRight: false },
  { id: 'contactNo2', label: 'CONTACT NUMBER2', alignRight: false },
  { id: 'emailAddress2', label: 'EMAIL ADDRESS2', alignRight: false },
  { id: 'link', label: 'LINK', alignRight: false },
  { id: 'ratesPresentedIntl', label: 'RATES PRESENTED INTL', alignRight: false },
  { id: 'ratesPresentedDomestic', label: 'RATES PRESENTED DOMESTIC', alignRight: false },
  { id: 'ratesPresentedIEX', label: 'RATES PRESENTED FOR INBOUND (IEX)', alignRight: false },
  { id: 'creditLimit', label: 'CREDIT LIMIT', alignRight: false },
  { id: 'accountExecutive', label: 'ACCOUNT EXECUTIVE', alignRight: false },
  { id: 'areaCode', label: 'AREA CODE', alignRight: false },
  { id: 'ownership', label: 'OWNERSHIP', alignRight: false },
  { id: 'branch', label: 'BRANCH', alignRight: false },
  { id: 'vat', label: 'VAT (YES/NO)', alignRight: false },
  { id: 'creditTerms', label: 'CREDIT TERMS', alignRight: false },
  { id: 'fsc', label: 'FSC', alignRight: false },
  { id: 'esc', label: 'ESC', alignRight: false },
  { id: 'localBilling', label: 'LOCAL BILLING', alignRight: false },
  { id: 'oexInvTemplate', label: 'OEX INVOICE TEMPLATE', alignRight: false },
  { id: 'pickupDate', label: 'PICKUP DATE', alignRight: false },
  { id: 'statusMessage', label: 'STATUS', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_detail) => _detail.companyName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomerUploader() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id_');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [uploadedData, setUploadedData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (selectedFiles && selectedFiles.length > 0) {
      upload();
    }
  }, [selectedFiles]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const downloadTemplate = () => {
    UtilsService.getUploadTemplate('Customers_File')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const link = document.createElement('a');
          link.href = data.responseData;
          link.setAttribute('download', 'Customers_File_Template.xlsx');
          document.body.appendChild(link);
          link.click();

          //showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showAlert('error', 'Could not download template!');
      });
  };

  const downloadLastUploaded = () => {
    UtilsService.getLastUploadedFile('Customers_File')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const link = document.createElement('a');
          link.href = data.responseData;
          link.setAttribute('download', 'Customers_File.xlsx');
          document.body.appendChild(link);
          link.click();

          //showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showAlert('error', 'Could not Download Last Uploaded File file!');
      });
  };

  const download = () => {
    setDownloading(true);

    CustomerUploaderService.download()
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        console.log(data);

        if (data.responseCode === 200) {
          const outputFilename = `Customers_File.xls`;

          const byteCharacters = atob(data.responseData.excel);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          console.log(byteArray);

          const blob = await new Blob([byteArray], { type: 'application/excel' });
          const url = await window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();

          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }

        setDownloading(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        setDownloading(false);
      });
  };

  const upload = () => {
    const currentFile = selectedFiles[0];
    setProgress(0);
    setUploadedData([]);
    setCurrentFile(currentFile);
    setUploading(true);

    CustomerUploaderService.upload(currentFile, (event) => {
      setProgress(Math.round((90 * event.loaded) / event.total));
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          setUploadedData(data.responseData);
          showAlert('success', data.responseMessage);
          setProgress(100);
        } else {
          setUploadedData([]);
          showAlert('error', data.responseMessage);
          setProgress(100);
        }

        setUploading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setProgress(0);
        showAlert('error', 'Could not upload the file!');
        setCurrentFile(undefined);

        setUploading(false);
      });

    document.getElementById('btn-upload').value = '';
    setSelectedFiles(undefined);
  };

  const uploadAgain = () => {
    setProgress(0);
    setUploadedData([]);
    setCurrentFile(undefined);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = uploadedData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - uploadedData.length) : 0;

  const filteredDetails = applySortFilter(uploadedData, getComparator(order, orderBy), filterName);

  const isDetailNotFound = filteredDetails.length === 0;

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Page title={`Customer Uploader | ${process.env.REACT_APP_APP_NAME}`}>
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={hideAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>

        <Card className="card-download">
          <div className="card-download-container">
            <LoadingButton
              className="btn-download"
              loading={downloading}
              variant="outlined"
              component="span"
              onClick={download}
            >
              Download Customers File
            </LoadingButton>
          </div>
        </Card>

        <Card className="card-upload">
          <div className="card-upload-container">
            {uploading ? (
              <LoadingButton className="btn-choose" loading variant="outlined" component="span">
                Upload
              </LoadingButton>
            ) : !currentFile ? (
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={selectFile}
                />
                {/* <span>Drop .xlsx here or</span>  */}
                <Button className="btn-choose" variant="outlined" component="span">
                  Choose File
                </Button>
              </label>
            ) : (
              <Button
                className="btn-again"
                variant="outlined"
                component="span"
                onClick={uploadAgain}
              >
                Upload Again
              </Button>
            )}
            {currentFile && (
              <Box className="mb25" display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                </Box>
              </Box>
            )}
            <Box width="100%" mr={1} mt={3}>
              <Link
                className="download-template"
                component="button"
                variant="body2"
                onClick={downloadTemplate}
              >
                Download Template
              </Link>
              {' or '}
              <Link
                className="download-template"
                component="button"
                variant="body2"
                onClick={downloadLastUploaded}
              >
                Download Last Uploaded File
              </Link>
            </Box>
          </div>
        </Card>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={uploadedData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredDetails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const {
                        id_,
                        startTime,
                        completionTime,
                        email,
                        name,
                        accountNo,
                        modePayment,
                        companyName,
                        address,
                        tin,
                        contactPerson,
                        position,
                        contactNo,
                        emailAddress,
                        billingContactPerson,
                        position2,
                        contactNo2,
                        emailAddress2,
                        link,
                        ratesPresentedIntl,
                        ratesPresentedDomestic,
                        ratesPresentedIEX,
                        creditLimit,
                        accountExecutive,
                        areaCode,
                        ownership,
                        branch,
                        vat,
                        creditTerms,
                        fsc,
                        esc,
                        localBilling,
                        oexInvTemplate,
                        pickupDate,
                        statusCode,
                        statusMessage
                      } = row;
                      const isItemSelected = selected.indexOf(id_) !== -1;

                      return (
                        <TableRow
                          hover
                          key={i}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{id_}</TableCell>
                          <TableCell align="left">
                            {startTime !== '' ? moment(startTime).format('M/d/yyyy H:mm:ss') : ''}
                          </TableCell>
                          <TableCell align="left">
                            {completionTime !== ''
                              ? moment(completionTime).format('M/d/yyyy H:mm:ss')
                              : ''}
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{accountNo}</TableCell>
                          <TableCell align="left">{modePayment}</TableCell>
                          <TableCell align="left">{companyName}</TableCell>
                          <TableCell align="left">{address}</TableCell>
                          <TableCell align="left">{tin}</TableCell>
                          <TableCell align="left">{contactPerson}</TableCell>
                          <TableCell align="left">{position}</TableCell>
                          <TableCell align="left">{contactNo}</TableCell>
                          <TableCell align="left">{emailAddress}</TableCell>
                          <TableCell align="left">{billingContactPerson}</TableCell>
                          <TableCell align="left">{position2}</TableCell>
                          <TableCell align="left">{contactNo2}</TableCell>
                          <TableCell align="left">{emailAddress2}</TableCell>
                          <TableCell align="left">{link}</TableCell>
                          <TableCell align="left">{ratesPresentedIntl}</TableCell>
                          <TableCell align="left">{ratesPresentedDomestic}</TableCell>
                          <TableCell align="left">{ratesPresentedIEX}</TableCell>
                          <TableCell align="left">{creditLimit}</TableCell>
                          <TableCell align="left">{accountExecutive}</TableCell>
                          <TableCell align="left">{areaCode}</TableCell>
                          <TableCell align="left">{ownership}</TableCell>
                          <TableCell align="left">{branch}</TableCell>
                          <TableCell align="left">{vat}</TableCell>
                          <TableCell align="left">{creditTerms}</TableCell>
                          <TableCell align="left">{fsc}</TableCell>
                          <TableCell align="left">{esc}</TableCell>
                          <TableCell align="left">{localBilling}</TableCell>
                          <TableCell align="left">{oexInvTemplate}</TableCell>
                          <TableCell align="left">{pickupDate}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(statusCode !== '200' && 'error') || 'success'}
                            >
                              {statusMessage}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={35} />
                    </TableRow>
                  )}
                </TableBody>
                {uploadedData.length > 0 && isDetailNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={35} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={uploadedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
