import http from '../../http-common';

class CountriesUploaderService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append('file', file);

    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`,
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'application/json',
      onUploadProgress
    };

    return http.post('/api/countries/upload', formData, config);
  }

  download() {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.get('/api/countries/download', config);
  }
}

export default new CountriesUploaderService();
