import * as Yup from 'yup';
import React, { useEffect, useState, forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { filter } from 'lodash';
import { ThreeCircles } from 'react-loader-spinner';
import Draggable from 'react-draggable';
// material
import {
  Stack,
  TextField,
  Container,
  Autocomplete,
  Paper,
  Snackbar,
  Alert,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Divider,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/_dashboard/table';
import Miscellaneous from '../../components/_dashboard/miscellaneous';
//
import GenerateInvoiceService from '../../services/billing-and-invoicing/GenerateInvoice.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TABLE_HEAD = [
  { id: 'invoiceNo', label: 'Invoice Number', alignRight: false },
  { id: 'invoiceDate', label: 'Invoice Date', alignRight: false },
  { id: 'invoiceBy', label: 'Invoice By', alignRight: false },
  { id: '', label: 'Action', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_detail) =>
        _detail.invoice_no.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _detail.invoice_date.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _detail.invoice_by.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function miscPaperComponent(props) {
  return (
    <Draggable handle="#misc-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function confirmPaperComponent(props) {
  return (
    <Draggable handle="#confirm-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function uidPaperComponent(props) {
  return (
    <Draggable handle="#uid-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function GenerateInvoice() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [invDate, setInvDate] = useState([null, null]);
  const [invDateFrom, setInvDateFrom] = useState('');
  const [invDateTo, setInvDateTo] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [department, setDepartment] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [customersListLoaded, setCustomersListLoaded] = useState(false);
  const [departmentsList, setDepartmentsList] = useState([]);
  const [departmentsListLoaded, setDepartmentsListLoaded] = useState(false);
  const [searchInvoiceNo, setSearchInvoiceNo] = useState('');
  const [searchORNo, setSearchORNo] = useState('');
  const [updateInvNoUID, setUpdateInvNoUID] = useState('');
  const [newInvoiceDate, setNewInvoiceDate] = useState('');
  const [searchExpanded, setSearchExpanded] = React.useState(false);
  const [searchInvNo, setSearchInvNo] = useState('');
  const [searchCustomer, setSearchCustomer] = useState(null);
  const [searchInvDate, setSearchInvDate] = useState([null, null]);
  const [searchInvDateFrom, setSearchInvDateFrom] = useState('');
  const [searchInvDateTo, setSearchInvDateTo] = useState('');
  const [searchDepartment, setSearchDepartment] = useState(null);
  const [openMisc, setOpenMisc] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openUID, setOpenUID] = useState(false);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('invoice_no');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [custInvoiceData, setCustInvoiceData] = useState([]);

  const [invoiceMode, setInvoiceMode] = useState('');
  const [miscList, setMiscList] = useState([]);
  const [miscFeesUpdated, setMiscFeesUpdated] = useState(false);
  const [disableProceed, setDisableProceed] = useState(false);

  const [downloadExcel, setDownloadExcel] = useState(false);

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(async () => {
    setLoading(true);
    setCustomersList([]);
    setCustomersListLoaded(false);
    await GenerateInvoiceService.getCustomerForInvoice()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setCustomersList(data.responseData);
        setCustomersListLoaded(true);
        // setLoading(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        // setLoading(false);
      });

    // setLoading(true);
    setDepartmentsList([]);
    setDepartmentsListLoaded(false);
    await GenerateInvoiceService.getOEXDepartments()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setDepartmentsList(data.responseData);
        setDepartmentsListLoaded(true);
        // setLoading(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        // setLoading(false);
      });

    setLoading(false);
  }, []);

  /* ** Start Generate Invoice ** */
  const schemaGenerate = Yup.object().shape({
    customer: Yup.object().nullable().required('Customer is required'),
    invDateFrom: Yup.string().required('Transaction Date from is required'),
    invDateTo: Yup.string().required('Transaction Date to is required')
  });

  const formikGenerate = useFormik({
    initialValues: {
      customer: null,
      invDateFrom: '',
      invDateTo: '',
      invoiceNo: '',
      invoiceDate: '',
      department: null
    },
    validationSchema: schemaGenerate,
    onSubmit: () => {
      if (invoiceNo === '') {
        setSubmitting(false);
        setInvoiceMode('GENERATE');
        setMiscList([]);
        setOpenMisc(true);
      } else {
        checkInvoiceNoIfExist();
      }      
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setSubmitting
  } = formikGenerate;

  const checkInvoiceNoIfExist = () => {
    if (customer === null) {
      showAlert('error', 'Invalid customer');
    } else if (invoiceNo === '') {
      showAlert('error', 'Invalid invoice number');
    } else {
      setLoading(true);

      const data = {
        accountNo: customer.accountNo,
        invoiceNo: invoiceNo,
        source: customer.source
      };

      GenerateInvoiceService.checkInvoiceNoIfExist(data)
        .then((response) => {
          return response.data;
        })
        .then(async (data) => {
          if (data.responseCode === 200) {
            setSubmitting(false);
            setLoading(false);
            setInvoiceMode('GENERATE');
            setMiscList([]);
            setOpenMisc(true);
          } else if (data.responseCode === 409) {
            setSubmitting(false);
            setLoading(false);
            setOpenConfirm(true);
          } else {
            showAlert('error', `${data.responseMessage}. Unable to proceed.`);
            setSubmitting(false);
            setLoading(false);
          }
        })
        .catch((error) => {
          showAlert('error', error.message);
          setSubmitting(false);
          setLoading(false);
        });
    }
  };

  const generateInvoice = () => {
    if (customer === null) {
      showAlert('error', 'Invalid customer');
    } else {
      setLoading(true);
      setSubmitting(true);

      const totMiscFees = parseFloat(totalMisc(miscList)).toFixed(2);

      const data = {
        accountNo: customer.accountNo,
        transDateFrom: moment(invDateFrom).format('YYYY-MM-DD'),
        transDateTo: moment(invDateTo).format('YYYY-MM-DD'),
        invoiceNo: invoiceNo,
        invoiceDate: invoiceDate !== '' ? moment(invoiceDate).format('YYYY-MM-DD') : '',
        department: department ? department.department : '',
        source: customer.source,
        miscFees: miscList,
        totMiscFees: totMiscFees
      };

      let urlFinal = '';
      let urlFinal2 = '';

      GenerateInvoiceService.generateInvoice(data)
        .then((response) => {
          return response.data;
        })
        .then(async (data) => {
          if (data.responseCode === 200) {
            /** Invoice **/
            const byteCharacters = atob(data.responseData.pdfInvoice);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = await new Blob([byteArray], { type: 'application/pdf' });
            const url = await window.URL.createObjectURL(blob);
            urlFinal = url;

            if (urlFinal !== undefined && urlFinal !== '') {
              // window.open(urlFinal,'_blank', 'toolbar=0,location=0,menubar=0');
              window.open(urlFinal, '_blank');
            }

            /** Invoice Details **/
            if (data.responseData.pdfDetails !== null) {
              const byteCharacters2 = atob(data.responseData.pdfDetails);
              const byteNumbers2 = new Array(byteCharacters2.length);
              for (let i = 0; i < byteCharacters2.length; i++) {
                byteNumbers2[i] = byteCharacters2.charCodeAt(i);
              }
              const byteArray2 = new Uint8Array(byteNumbers2);
              const blob2 = await new Blob([byteArray2], { type: 'application/pdf' });
              const url2 = await window.URL.createObjectURL(blob2);
              urlFinal2 = url2;

              if (urlFinal2 !== undefined && urlFinal2 !== '') {
                // window.open(urlFinal2,'_blank', 'toolbar=0,location=0,menubar=0');
                window.open(urlFinal2, '_blank');
              }
            }

            /** Invoice Details in Excel **/
            if (
              downloadExcel &&
              data.responseData.excelDetails &&
              data.responseData.excelDetails !== null
            ) {
              const outputFilename = `Invoice_Details.xls`;

              const byteCharacters = atob(data.responseData.excelDetails);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);

              const blob = await new Blob([byteArray], { type: 'application/excel' });
              const url = await window.URL.createObjectURL(blob);

              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', outputFilename);
              document.body.appendChild(link);
              link.click();
            }

            setMiscList([]);
            setDownloadExcel(false);
            showAlert('success', data.responseMessage);
            setSubmitting(false);
            setLoading(false);
          } else {
            showAlert('error', data.responseMessage);
            setSubmitting(false);
            setLoading(false);
          }
        })
        .catch((error) => {
          showAlert('error', error.message);
          setSubmitting(false);
          setLoading(false);
        });
    }
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirmProceed = () => {
    setOpenConfirm(false);
    setSubmitting(false);
    setInvoiceMode('GENERATE');
    setOpenMisc(true);
  };
  /* ** End Generate Invoice ** */

  /* ** Start Search Invoice (Per Invoice Number) ** */
  const schemaSearch = Yup.object().shape({
    // searchORNo: Yup.string(),
    // searchInvoiceNo: Yup.string().when('searchORNo', {
    //   is: (value) => value === '',
    //   then: Yup.string().required('Invoice number is required')
    // })
  });

  const formikSearch = useFormik({
    initialValues: {
      searchORNo: '',
      searchInvoiceNo: ''
    },
    validationSchema: schemaSearch,
    onSubmit: () => {
      formikSearch.setTouched({}, false);
      setInvoiceMode('SEARCH');
      searchInvoice();
    }
  });

  const errorsS = formikSearch.errors;
  const touchedS = formikSearch.touched;
  const handleSubmitS = formikSearch.handleSubmit;
  const isSubmittingS = formikSearch.isSubmitting;
  const getFieldPropsS = formikSearch.getFieldProps;
  const setFieldValueS = formikSearch.setFieldValue;
  const setSubmittingS = formikSearch.setSubmitting;

  const searchInvoice = () => {
    setLoading(true);

    const totMiscFees = parseFloat(totalMisc(miscList)).toFixed(2);

    const data = {
      invoiceNo: searchInvoiceNo,
      orNumber: searchORNo
    };

    GenerateInvoiceService.searchInvoice(data)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        // if (data.responseCode === 200) {
        //   setSearchInvNo(data.responseData.invNumber);
        //   getInvMiscFees(data.responseData.invNumber);
        // } else {
        //   showAlert('error', data.responseMessage);
        //   setSubmittingS(false);
        //   setLoading(false);
        // }

        if (data.responseCode === 200) {
          setCustInvoiceData(data.responseData);
          showAlert('success', data.responseMessage);
          setSubmittingS(false);
          setLoading(false);
          document.getElementById('custInvoiceTable').scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center'
          });
        } else {
          setCustInvoiceData([]);
          showAlert('error', data.responseMessage);
          setSubmittingS(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
        setSubmittingS(false);
        setLoading(false);
      });
  };

  const searchInvoiceData = () => {
    setLoading(true);

    const totMiscFees = parseFloat(totalMisc(miscList)).toFixed(2);

    const data = {
      invoiceNo: searchInvNo,
      miscFees: miscList,
      totMiscFees: totMiscFees,
      miscFeesUpdated: miscFeesUpdated
    };

    let urlFinal = '';
    let urlFinal2 = '';

    GenerateInvoiceService.searchInvoiceData(data)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        if (data.responseCode === 200) {
          /** Invoice **/
          const byteCharacters = atob(data.responseData.pdfInvoice);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = await new Blob([byteArray], { type: 'application/pdf' });
          const url = await window.URL.createObjectURL(blob);
          urlFinal = url;

          if (urlFinal !== undefined && urlFinal !== '') {
            // window.open(urlFinal,'_blank', 'toolbar=0,location=0,menubar=0');
            window.open(urlFinal, '_blank');
          }

          /** Invoice Details **/
          if (data.responseData.pdfDetails !== null) {
            const byteCharacters2 = atob(data.responseData.pdfDetails);
            const byteNumbers2 = new Array(byteCharacters2.length);
            for (let i = 0; i < byteCharacters2.length; i++) {
              byteNumbers2[i] = byteCharacters2.charCodeAt(i);
            }
            const byteArray2 = new Uint8Array(byteNumbers2);
            const blob2 = await new Blob([byteArray2], { type: 'application/pdf' });
            const url2 = await window.URL.createObjectURL(blob2);
            urlFinal2 = url2;

            if (urlFinal2 !== undefined && urlFinal2 !== '') {
              // window.open(urlFinal2,'_blank', 'toolbar=0,location=0,menubar=0');
              window.open(urlFinal2, '_blank');
            }
          }

          /** Invoice Details in Excel **/
          if (
            downloadExcel &&
            data.responseData.excelDetails &&
            data.responseData.excelDetails !== null
          ) {
            const outputFilename = `Invoice_Details.xls`;

            const byteCharacters = atob(data.responseData.excelDetails);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const blob = await new Blob([byteArray], { type: 'application/excel' });
            const url = await window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
          }

          setMiscList([]);
          setDownloadExcel(false);
          showAlert('success', data.responseMessage);
          setSubmittingS(false);
          setLoading(false);
        } else {
          showAlert('error', data.responseMessage);
          setSubmittingS(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
        setSubmittingS(false);
        setLoading(false);
      });
  };

  const getInvMiscFees = (searchInvNo) => {
    setLoading(true);
    setMiscList([]);
    setMiscFeesUpdated(false);

    const data = {
      invoiceNo: searchInvNo
    };

    GenerateInvoiceService.getInvMiscFees(data)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        if (data.responseCode === 200) {
          let _miscList = [];
          data.responseData.map((item) => {
            const misc = {
              miscName: item.misc_name,
              miscValue: item.misc_value,
              miscVat: item.misc_vat
            };

            _miscList.push(misc);
          });
          setMiscList(_miscList);
          setSubmittingS(false);
          setLoading(false);
          setOpenMisc(true);
        } else {
          setMiscList([]);
          setSubmittingS(false);
          setLoading(false);
          setOpenMisc(true);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
        setSubmittingS(false);
        setLoading(false);
      });
  };
  /* ** End Search Invoice (Per Invoice Number) ** */

  /* ** Start Search Invoice (Per Customer and Transaction Date range) ** */
  const schemaSearch2 = Yup.object().shape({
    searchCustomer: Yup.object().nullable().required('Customer is required'),
    searchInvDateFrom: Yup.string().required('Transaction date from is required'),
    searchInvDateTo: Yup.string().required('Transaction date to is required')
  });

  const formikSearch2 = useFormik({
    initialValues: {
      searchCustomer: null,
      searchInvDateFrom: '',
      searchInvDateTo: ''
    },
    validationSchema: schemaSearch2,
    onSubmit: () => {
      searchCustomerInvoice();
    }
  });

  const errorsS2 = formikSearch2.errors;
  const touchedS2 = formikSearch2.touched;
  const handleSubmitS2 = formikSearch2.handleSubmit;
  const isSubmittingS2 = formikSearch2.isSubmitting;
  const getFieldPropsS2 = formikSearch2.getFieldProps;
  const setFieldValueS2 = formikSearch2.setFieldValue;
  const setSubmittingS2 = formikSearch2.setSubmitting;

  const searchCustomerInvoice = () => {
    if (searchCustomer === null) {
      showAlert('error', 'Invalid customer');
    } else {
      setLoading(true);

      const data = {
        accountNo: searchCustomer.accountNo,
        transDateFrom: moment(searchInvDateFrom).format('YYYY-MM-DD'),
        transDateTo: moment(searchInvDateTo).format('YYYY-MM-DD'),
        source: searchCustomer.source
      };

      GenerateInvoiceService.searchCustomerInvoice(data)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data.responseCode === 200) {
            setCustInvoiceData(data.responseData);
            showAlert('success', data.responseMessage);
            setSubmittingS2(false);
            setLoading(false);
            document.getElementById('custInvoiceTable').scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center'
            });
          } else {
            setCustInvoiceData([]);
            showAlert('error', data.responseMessage);
            setSubmittingS2(false);
            setLoading(false);
          }
        })
        .catch((error) => {
          showAlert('error', error.message);
          setSubmittingS2(false);
          setLoading(false);
        });
    }
  };

  const handleShowInvoice = (invoiceNo) => {
    setSearchInvNo(invoiceNo);
    setInvoiceMode('SEARCH');
    getInvMiscFees(invoiceNo);

    // setLoading(true);

    // const data = {
    //   invoiceNo: invoiceNo
    // };

    // let urlFinal = '';
    // let urlFinal2 = '';

    // GenerateInvoiceService.searchInvoice(data)
    //   .then((response) => {
    //     return response.data;
    //   })
    //   .then(async (data) => {
    //     if (data.responseCode === 200) {
    //       /** Invoice **/
    //       const byteCharacters = atob(data.responseData.pdfInvoice);
    //       const byteNumbers = new Array(byteCharacters.length);
    //       for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //       }
    //       const byteArray = new Uint8Array(byteNumbers);
    //       const blob = await new Blob([byteArray], { type: 'application/pdf' });
    //       const url = await window.URL.createObjectURL(blob);
    //       urlFinal = url;

    //       if (urlFinal !== undefined && urlFinal !== '') {
    //         // window.open(urlFinal,'_blank', 'toolbar=0,location=0,menubar=0');
    //         window.open(urlFinal, '_blank');
    //       }

    //       /** Invoice Details **/
    //       if (data.responseData.pdfDetails !== null) {
    //         const byteCharacters2 = atob(data.responseData.pdfDetails);
    //         const byteNumbers2 = new Array(byteCharacters2.length);
    //         for (let i = 0; i < byteCharacters2.length; i++) {
    //           byteNumbers2[i] = byteCharacters2.charCodeAt(i);
    //         }
    //         const byteArray2 = new Uint8Array(byteNumbers2);
    //         const blob2 = await new Blob([byteArray2], { type: 'application/pdf' });
    //         const url2 = await window.URL.createObjectURL(blob2);
    //         urlFinal2 = url2;

    //         if (urlFinal2 !== undefined && urlFinal2 !== '') {
    //           // window.open(urlFinal2,'_blank', 'toolbar=0,location=0,menubar=0');
    //           window.open(urlFinal2, '_blank');
    //         }
    //       }

    //       showAlert('success', data.responseMessage);
    //       setLoading(false);
    //     } else {
    //       showAlert('error', data.responseMessage);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     showAlert('error', error.message);
    //     setLoading(false);
    //   });
  };
  /* ** End Search Invoice (Per Customer and Transaction Date range) ** */

  /* ** Start Search Invoice (Per Department) ** */
  // const schemaSearch3 = Yup.object().shape({
  //   searchDepartment: Yup.object().nullable().required('Department is required')
  // });

  // const formikSearch3 = useFormik({
  //   initialValues: {
  //     searchDepartment: null
  //   },
  //   validationSchema: schemaSearch3,
  //   onSubmit: () => {
  //     searchInvoiceByDepartment();
  //   }
  // });

  // const errorsS3 = formikSearch3.errors;
  // const touchedS3 = formikSearch3.touched;
  // const handleSubmitS3 = formikSearch3.handleSubmit;
  // const isSubmittingS3 = formikSearch3.isSubmitting;
  // const getFieldPropsS3 = formikSearch3.getFieldProps;
  // const setFieldValueS3 = formikSearch3.setFieldValue;
  // const setSubmittingS3 = formikSearch3.setSubmitting;

  // const searchInvoiceByDepartment = () => {
  //   if (searchDepartment === null) {
  //     showAlert('error', 'Invalid department');
  //   } else {
  //     setLoading(true);

  //     const data = {
  //       department: searchDepartment.department
  //     };

  //     GenerateInvoiceService.searchInvoiceByDepartment(data)
  //       .then((response) => {
  //         return response.data;
  //       })
  //       .then((data) => {
  //         if (data.responseCode === 200) {
  //           setCustInvoiceData(data.responseData);
  //           showAlert('success', data.responseMessage);
  //           setSubmittingS3(false);
  //           setLoading(false);
  //           document.getElementById('custInvoiceTable').scrollIntoView({
  //             behavior: 'smooth',
  //             block: 'start',
  //             inline: 'center'
  //           });
  //         } else {
  //           setCustInvoiceData([]);
  //           showAlert('error', data.responseMessage);
  //           setSubmittingS3(false);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         showAlert('error', error.message);
  //         setSubmittingS3(false);
  //         setLoading(false);
  //       });
  //   }
  // };
  /* ** End Search Invoice (Per Department) ** */

  /* ** Start Customer Invoice Table ** */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = custInvoiceData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - custInvoiceData.length) : 0;

  const filteredDetails = applySortFilter(
    custInvoiceData,
    getComparator(order, orderBy),
    filterName
  );

  const isDetailNotFound = filteredDetails.length === 0;
  /* ** End Customer Invoice Table ** */

  const handleCloseMisc = () => {
    setOpenMisc(false);
  };

  const handleMiscProceed = () => {
    if (invoiceMode == 'GENERATE') {
      generateInvoice();
    } else {
      searchInvoiceData();
    }
    setOpenMisc(false);
  };

  const onAddMisc = (name, value, vat) => {
    const newMiscList = [...miscList];
    newMiscList.push({
      miscName: name,
      miscValue: value,
      miscVat: vat
    });
    setMiscList(newMiscList);
    setMiscFeesUpdated(invoiceMode == 'SEARCH');
  };

  const onEditMisc = (name, newName, newValue, newVat) => {
    const newMiscList = miscList.map((misc) => {
      if (misc.miscName === name) {
        return { ...misc, miscName: newName, miscValue: newValue, miscVat: newVat };
      }
      return misc;
    });

    setMiscList(newMiscList || []);
    setMiscFeesUpdated(invoiceMode == 'SEARCH');
  };

  const onDeleteMisc = (name) => {
    const newMiscList = miscList
      .filter((misc) => misc.miscName !== name)
      .map((misc) => {
        return misc;
      });
    setMiscList(newMiscList || []);
    setMiscFeesUpdated(invoiceMode == 'SEARCH');
  };

  const totalMisc = (misc) => {
    return misc.map(({ miscValue }) => parseFloat(miscValue)).reduce((sum, i) => sum + i, 0);
  };

  const handleSearchExpandedChange = (panel) => (event, isExpanded) => {
    setSearchExpanded(isExpanded ? panel : false);
  };

  const handleShowUID = (invoiceNo) => {
    setUpdateInvNoUID(invoiceNo);
    setOpenUID(true);
  };

  const handleCloseUID = () => {
    setOpenUID(false);
    setUpdateInvNoUID('');
    setNewInvoiceDate('');
    formikUID.setTouched({}, false);
  };

  /* ** Start Update Invoice Date ** */
  const schemaUID = Yup.object().shape({
    newInvoiceDate: Yup.string().required('New Invoice Date is required')
  });

  const formikUID = useFormik({
    initialValues: {
      newInvoiceDate: ''
    },
    validationSchema: schemaUID,
    onSubmit: () => {
      formikUID.setTouched({}, false);
      updateInvoiceDate();
    }
  });

  const errorsUID = formikUID.errors;
  const touchedUID = formikUID.touched;
  const handleSubmitUID = formikUID.handleSubmit;
  const isSubmittingUID = formikUID.isSubmitting;
  const getFieldPropsUID = formikUID.getFieldProps;
  const setFieldValueUID = formikUID.setFieldValue;
  const setSubmittingUID = formikUID.setSubmitting;

  const updateInvoiceDate = () => {
    if (updateInvNoUID === '') {
      showAlert('error', 'Invalid invoice number');
    } else {
      setLoading(true);

      const data = {
        invoiceNo: updateInvNoUID,
        newInvoiceDate: moment(newInvoiceDate).format('YYYY-MM-DD')
      };

      GenerateInvoiceService.updateInvoiceDate(data)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data.responseCode === 200) {
            showAlert('success', data.responseMessage);
            setSubmittingUID(false);
            setLoading(false);
            handleCloseUID();

            if (searchExpanded === 'panel1') {
              handleSubmitS();
            } else {
              handleSubmitS2();
            }
          } else {
            showAlert('error', data.responseMessage);
            setSubmittingUID(false);
            setLoading(false);
          }
        })
        .catch((error) => {
          showAlert('error', error.message);
          setSubmittingUID(false);
          setLoading(false);
        });
    }
  };
  /* ** End Update Invoice Date ** */

  return (
    <>
      <ThreeCircles
        height="100"
        width="100"
        color="#ed1c24"
        wrapperStyle={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '99999',
          textAlign: 'center'
        }}
        wrapperClass=""
        visible={loading}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
      <Page title={`Generate Invoice | ${process.env.REACT_APP_APP_NAME}`}>
        <Container>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={hideAlert}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </MuiAlert>
            </Snackbar>
          </Stack>
        </Container>
        <Container maxWidth="md">
          <Card sx={{ minWidth: 275 }} style={{ paddingBottom: '10px' }}>
            <FormikProvider value={formikGenerate}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <CardHeader title="Generate Invoice" />
                <CardContent>
                  <Stack spacing={2}>
                    <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                      <Autocomplete
                        fullWidth
                        disabled={!customersListLoaded}
                        options={customersList}
                        noOptionsText="No record found"
                        groupBy={(option) => option.source}
                        getOptionLabel={(option) => option.companyName}
                        onChange={(e, value) => {
                          setCustomer(value);
                          setFieldValue('customer', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer"
                            name="customer"
                            {...getFieldProps('customer')}
                            error={Boolean(touched.customer && errors.customer)}
                            helperText={touched.customer && errors.customer}
                          />
                        )}
                      />
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                          startText="Transaction Date From"
                          endText="Transaction Date To"
                          value={invDate}
                          onChange={(value) => {
                            setInvDate(value);
                            setInvDateFrom(value[0] === null ? '' : value[0]);
                            setInvDateTo(value[1] === null ? '' : value[1]);
                            setFieldValue('invDateFrom', value[0] === null ? '' : value[0]);
                            setFieldValue('invDateTo', value[1] === null ? '' : value[1]);
                          }}
                          renderInput={(startProps, endProps) => (
                            <React.Fragment>
                              <Stack
                                direction={{ xs: 'column', sm: 'row', width: '100%' }}
                                spacing={2}
                              >
                                <TextField
                                  {...startProps}
                                  fullWidth
                                  name="invDateFrom"
                                  {...getFieldProps('invDateFrom')}
                                  error={Boolean(touched.invDateFrom && errors.invDateFrom)}
                                  helperText={touched.invDateFrom && errors.invDateFrom}
                                />
                                <TextField
                                  {...endProps}
                                  fullWidth
                                  name="invDateTo"
                                  {...getFieldProps('invDateTo')}
                                  error={Boolean(touched.invDateTo && errors.invDateTo)}
                                  helperText={touched.invDateTo && errors.invDateTo}
                                />
                              </Stack>
                            </React.Fragment>
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>

                    <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            id="invoiceNo"
                            name="invoiceNo"
                            label="Invoice Number (Optional)"
                            type="text"
                            value={invoiceNo}
                            inputProps={{ maxLength: 20 }}
                            onChange={(e) => {
                              setInvoiceNo(e.target.value);
                              setFieldValue('invoiceNo', e.target.value);
                            }}
                            error={Boolean(touched.invoiceNo && errors.invoiceNo)}
                            helperText={touched.invoiceNo && errors.invoiceNo}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="Invoice Date (Optional)"
                              value={invoiceDate}
                              onChange={(value) => {
                                setInvoiceDate(value);
                                setFieldValue('invoiceDate', value);
                              }}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  fullWidth
                                  name="invoiceDate"
                                  {...getFieldProps('invoiceDate')}
                                  error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                                  helperText={touched.invoiceDate && errors.invoiceDate}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                      <Autocomplete
                        fullWidth
                        disabled={!departmentsListLoaded}
                        options={departmentsList}
                        noOptionsText="No record found"
                        getOptionLabel={(option) => option.department}
                        onChange={(e, value) => {
                          setDepartment(value);
                          setFieldValue('department', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Department"
                            name="department"
                            {...getFieldProps('department')}
                            error={Boolean(touched.department && errors.department)}
                            helperText={touched.department && errors.department}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="outlined"
                    loading={isSubmitting}
                    sx={{ maxWidth: '200px', alignSelf: 'center' }}
                  >
                    Generate Invoice
                  </LoadingButton>
                </CardActions>
              </Form>
            </FormikProvider>
          </Card>
        </Container>

        <Container maxWidth="md" sx={{ marginTop: '20px' }}>
          <Card sx={{ minWidth: 275 }} style={{ paddingBottom: '10px' }}>
            <CardHeader title="Search Invoice" />
            <Accordion
              expanded={searchExpanded === 'panel1'}
              onChange={handleSearchExpandedChange('panel1')}
              sx={{ padding: '0 10px' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                  Per Invoice Number/OR Number
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormikProvider value={formikSearch}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmitS}>
                    <CardContent sx={{ padding: '0' }}>
                      <Stack spacing={2}>
                        <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                          <TextField
                            fullWidth
                            id="searchInvoiceNo"
                            name="searchInvoiceNo"
                            label="Invoice Number"
                            type="search"
                            value={searchInvoiceNo}
                            onChange={(e) => {
                              setSearchInvoiceNo(e.target.value);
                              setFieldValueS('searchInvoiceNo', e.target.value);
                            }}
                            error={Boolean(touchedS.searchInvoiceNo && errorsS.searchInvoiceNo)}
                            helperText={touchedS.searchInvoiceNo && errorsS.searchInvoiceNo}
                          />
                          <TextField
                            fullWidth
                            id="searchORNo"
                            name="searchORNo"
                            label="OR Number"
                            type="search"
                            value={searchORNo}
                            onChange={(e) => {
                              setSearchORNo(e.target.value);
                              setFieldValueS('searchORNo', e.target.value);
                            }}
                            error={Boolean(touchedS.searchORNo && errorsS.searchORNo)}
                            helperText={touchedS.searchORNo && errorsS.searchORNo}
                          />
                        </Stack>
                      </Stack>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="outlined"
                        loading={isSubmittingS}
                        sx={{ maxWidth: '200px', alignSelf: 'center' }}
                      >
                        Search Invoice
                      </LoadingButton>
                    </CardActions>
                  </Form>
                </FormikProvider>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={searchExpanded === 'panel2'}
              onChange={handleSearchExpandedChange('panel2')}
              sx={{ padding: '0 10px' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                  Per Customer and Transaction Date range
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormikProvider value={formikSearch2}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmitS2}>
                    <CardContent sx={{ padding: '0' }}>
                      <Stack spacing={2}>
                        <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                          <Autocomplete
                            fullWidth
                            disabled={!customersListLoaded}
                            options={customersList}
                            noOptionsText="No record found"
                            groupBy={(option) => option.source}
                            getOptionLabel={(option) => option.companyName}
                            onChange={(e, value) => {
                              setSearchCustomer(value);
                              setFieldValueS2('searchCustomer', value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Customer"
                                name="searchCustomer"
                                {...getFieldPropsS2('searchCustomer')}
                                error={Boolean(touchedS2.searchCustomer && errorsS2.searchCustomer)}
                                helperText={touchedS2.searchCustomer && errorsS2.searchCustomer}
                              />
                            )}
                          />
                        </Stack>

                        <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateRangePicker
                              startText="Transaction Date From"
                              endText="Transaction Date To"
                              value={searchInvDate}
                              onChange={(value) => {
                                setSearchInvDate(value);
                                setSearchInvDateFrom(value[0] === null ? '' : value[0]);
                                setSearchInvDateTo(value[1] === null ? '' : value[1]);
                                setFieldValueS2(
                                  'searchInvDateFrom',
                                  value[0] === null ? '' : value[0]
                                );
                                setFieldValueS2(
                                  'searchInvDateTo',
                                  value[1] === null ? '' : value[1]
                                );
                              }}
                              renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                  <Stack
                                    direction={{ xs: 'column', sm: 'row', width: '100%' }}
                                    spacing={2}
                                  >
                                    <TextField
                                      {...startProps}
                                      fullWidth
                                      name="searchInvDateFrom"
                                      {...getFieldPropsS2('searchInvDateFrom')}
                                      error={Boolean(
                                        touchedS2.searchInvDateFrom && errorsS2.searchInvDateFrom
                                      )}
                                      helperText={
                                        touchedS2.searchInvDateFrom && errorsS2.searchInvDateFrom
                                      }
                                    />
                                    <TextField
                                      {...endProps}
                                      fullWidth
                                      name="searchInvDateTo"
                                      {...getFieldPropsS2('searchInvDateTo')}
                                      error={Boolean(
                                        touchedS2.searchInvDateTo && errorsS2.searchInvDateTo
                                      )}
                                      helperText={
                                        touchedS2.searchInvDateTo && errorsS2.searchInvDateTo
                                      }
                                    />
                                  </Stack>
                                </React.Fragment>
                              )}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </Stack>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="outlined"
                        loading={isSubmittingS2}
                        sx={{ maxWidth: '200px', alignSelf: 'center' }}
                      >
                        Search Invoice
                      </LoadingButton>
                    </CardActions>
                  </Form>
                </FormikProvider>
              </AccordionDetails>
            </Accordion>
            {/* <Accordion
              expanded={searchExpanded === 'panel3'}
              onChange={handleSearchExpandedChange('panel3')}
              sx={{ padding: '0 10px' }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ color: 'text.secondary', fontSize: '0.875rem' }}>
                  Per Department
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormikProvider value={formikSearch3}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmitS3}>
                    <CardContent sx={{ padding: '0' }}>
                      <Stack spacing={2}>
                        <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                          <Autocomplete
                            fullWidth
                            disabled={!departmentsListLoaded}
                            options={departmentsList}
                            noOptionsText="No record found"
                            getOptionLabel={(option) => option.department}
                            onChange={(e, value) => {
                              setSearchDepartment(value);
                              setFieldValueS3('searchDepartment', value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Department"
                                name="searchDepartment"
                                {...getFieldPropsS3('searchDepartment')}
                                error={Boolean(
                                  touchedS3.searchDepartment && errorsS3.searchDepartment
                                )}
                                helperText={touchedS3.searchDepartment && errorsS3.searchDepartment}
                              />
                            )}
                          />
                        </Stack>
                      </Stack>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="outlined"
                        loading={isSubmittingS2}
                        sx={{ maxWidth: '200px', alignSelf: 'center' }}
                      >
                        Search Invoice
                      </LoadingButton>
                    </CardActions>
                  </Form>
                </FormikProvider>
              </AccordionDetails>
            </Accordion> */}
            {custInvoiceData.length > 0 && (
              <>
                <Divider />
                <Stack id="custInvoiceTable" p="8px 16px 16px">
                  <ListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                  />

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <ListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={custInvoiceData.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredDetails
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => {
                              const { invoice_no, invoice_date, invoice_by } = row;
                              const isItemSelected = selected.indexOf(invoice_no) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={i}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell align="left">{invoice_no}</TableCell>
                                  <TableCell align="left">
                                    {invoice_date}
                                    <Tooltip title="Update Invoice Date">
                                      <IconButton onClick={() => handleShowUID(invoice_no)}>
                                        <AutorenewIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell align="left">{invoice_by}</TableCell>
                                  <TableCell align="left">
                                    <Tooltip title="Show Invoice">
                                      <IconButton onClick={() => handleShowInvoice(invoice_no)}>
                                        <VisibilityRoundedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={4} />
                            </TableRow>
                          )}
                        </TableBody>
                        {custInvoiceData.length > 0 && isDetailNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={custInvoiceData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Stack>
              </>
            )}
          </Card>
        </Container>
      </Page>

      {/** Miscellaneous Dialog **/}
      <Dialog
        open={openMisc}
        onClose={handleCloseMisc}
        PaperComponent={miscPaperComponent}
        aria-labelledby="misc-draggable-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
        style={{ minHeight: '500px' }}
      >
        <DialogTitle style={{ cursor: 'move', color: '#ed1c24' }} id="misc-draggable-dialog-title">
          Miscellaneous Fees
        </DialogTitle>
        <DialogContent>
          <Miscellaneous
            miscList={miscList}
            onAddMisc={onAddMisc}
            onEditMisc={onEditMisc}
            onDeleteMisc={onDeleteMisc}
            disableProceed={setDisableProceed}
          />
        </DialogContent>
        <DialogActions style={{ padding: '16px 24px' }}>
          <Stack direction="column" style={{ width: '100%' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <Stack style={{ width: '100%', fontWeight: 'bold ' }}>
                {`Total Misc.: ${parseFloat(totalMisc(miscList))
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
              </Stack>
              <Button autoFocus onClick={handleCloseMisc}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                onClick={handleMiscProceed}
                autoFocus
                disabled={disableProceed}
              >
                Proceed
              </LoadingButton>
            </Stack>
            <div style={{ textAlign: 'right' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={downloadExcel}
                    onChange={(e) => setDownloadExcel(e.target.checked)}
                    name="excel"
                  />
                }
                label="Download Details in Excel"
                value="start"
                labelPlacement="start"
              />
            </div>
          </Stack>
        </DialogActions>
      </Dialog>

      {/** Confirmation Dialog **/}
      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        PaperComponent={confirmPaperComponent}
        aria-labelledby="confirm-draggable-dialog-title"
      >
        <DialogTitle
          style={{ cursor: 'move', color: '#ed1c24' }}
          id="confirm-draggable-dialog-title"
        >
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invoice number already exists under the same account. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseConfirm}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            onClick={handleConfirmProceed}
            autoFocus
          >
            Proceed
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/** Update Invoice Date Dialog **/}
      <Dialog
        open={openUID}
        onClose={handleCloseUID}
        PaperComponent={uidPaperComponent}
        aria-labelledby="uid-draggable-dialog-title"
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogTitle style={{ cursor: 'move', color: '#ed1c24' }} id="uid-draggable-dialog-title">
          Update Invoice Date
        </DialogTitle>
        <FormikProvider value={formikUID}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmitUID}>
            <DialogContent>
              <Box pt={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="New Invoice Date"
                    value={newInvoiceDate}
                    onChange={(value) => {
                      setNewInvoiceDate(value);
                      setFieldValueUID('newInvoiceDate', value);
                    }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        name="newInvoiceDate"
                        {...getFieldPropsUID('newInvoiceDate')}
                        errors={Boolean(touchedUID.newInvoiceDate && errorsUID.newInvoiceDate)}
                        helperText={touchedUID.newInvoiceDate && errorsUID.newInvoiceDate}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseUID}>
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmittingUID} autoFocus>
                Update
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
}
