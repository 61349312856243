import * as Yup from 'yup';
import { useState, forwardRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Draggable from 'react-draggable';
// material
import {
  Button,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import Auth from '../../../services/Auth.service';

// ----------------------------------------------------------------------

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ChangePassForm() {
  const navigate = useNavigate();
  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [currPassword, setCurrPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);

  const cookies = new Cookies();

  const LoginSchema = Yup.object().shape({
    currPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string().required('New Password is required'),
    confirmNewPassword: Yup.string()
      .required('Confirm New Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'New Password and Confirm New Password does not match')
  });

  const formik = useFormik({
    initialValues: {
      currPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      handleClickOpen();
    }
  });

  const handleChangePass = () => {
    setOpen(false);

    const data = {
      email: global.userData.email,
      currPassword,
      newPassword,
      confirmNewPassword
    };

    Auth.changePass(data)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          handleLogout();
        } else {
          setSubmitting(false);
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        setSubmitting(false);
        showAlert('error', error.message);
      });
  };

  const handleLogout = () => {
    Auth.logout()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          cookies.remove('userData', { path: '/' });
          navigate('/login', { replace: true });
        } else {
          showAlert('error', data.responseMessage);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        setSubmitting(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSubmitting(false);
  };

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setSubmitting
  } = formik;

  const handleShowPassword = (pass) => {
    if (pass === 'CP') {
      setShowCurrPassword((show) => !show);
    } else if (pass === 'NP') {
      setShowNewPassword((show) => !show);
    } else if (pass === 'CNP') {
      setShowConfirmNewPassword((show) => !show);
    }
  };

  return (
    <>
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={hideAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>
      </Container>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              id="currPassword"
              name="currPassword"
              label="Current Password"
              autoComplete="current-password"
              type={showCurrPassword ? 'text' : 'password'}
              value={currPassword}
              onChange={(e) => {
                setCurrPassword(e.target.value ? e.target.value : '');
                setFieldValue('currPassword', e.target.value ? e.target.value : '');
              }}
              // {...getFieldProps('currPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleShowPassword('CP')} edge="end">
                      <Icon icon={showCurrPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.currPassword && errors.currPassword)}
              helperText={touched.currPassword && errors.currPassword}
            />

            <TextField
              fullWidth
              id="newPassword"
              name="newPassword"
              label="New Password"
              autoComplete="new-password"
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => {
                //console.log(e);
                setNewPassword(e.target.value ? e.target.value : '');
                setFieldValue('newPassword', e.target.value ? e.target.value : '');
              }}
              // {...getFieldProps('newPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleShowPassword('NP')} edge="end">
                      <Icon icon={showNewPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />

            <TextField
              fullWidth
              id="confirmNewPassword"
              name="confirmNewPassword"
              label="Confirm New Password"
              autoComplete="confirm-new-password"
              type={showConfirmNewPassword ? 'text' : 'password'}
              value={confirmNewPassword}
              onChange={(e) => {
                //console.log(e);
                setConfirmNewPassword(e.target.value ? e.target.value : '');
                setFieldValue('confirmNewPassword', e.target.value ? e.target.value : '');
              }}
              // {...getFieldProps('confirmNewPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleShowPassword('CNP')} edge="end">
                      <Icon icon={showConfirmNewPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
              helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ mt: 5 }}
          >
            Change Password
          </LoadingButton>
        </Form>
      </FormikProvider>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', color: '#ed1c24' }} id="draggable-dialog-title">
          Change password confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>You will be logged out after you confirm change password.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleChangePass} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
