import { useState, useEffect, forwardRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
// material
import { Stack, Container, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import IdleTimeOutModal from '../../components/IdleTimeOutModal';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
//
import Auth from '../../services/Auth.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  backgroundColor: 'rgba(255,255,255,0.25)'
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const cookies = new Cookies();
  const userData = cookies.get('userData', { path: '/' });
  global.userData = userData;

  // Set timeout values
  const timeout = 1000 * 60 * 30;
  const promptTimeout = 1000 * 30;

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  // Modal open state
  const [openIdle, setOpenIdle] = useState(false);
  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    // In this case 30 minutes. Here you can open your prompt.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `reset()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setOpenIdle(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    // In this case 30 seconds. Here you can close your prompt and
    // perform what ever idle action you want such as log out your user.
    // Events will be rebound as long as `stopOnMount` is not set.
    handleLogout();
    setOpenIdle(false);
    setRemaining(0);    
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setOpenIdle(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, reset } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive
  });

  const handleStillHere = () => {
    setOpenIdle(false);
    reset();
  };

  const handleLogout = () => {
    Auth.logout()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          cookies.remove('userData', { path: '/' });
          Navigate('/login', { replace: true });
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <>
      {userData === undefined || userData === '' ? (
        <Navigate to="/login" />
      ) : (
        <>
          <Container>
            <Stack spacing={2} sx={{ width: '100%' }}>
              <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={hideAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
                  {alertMessage}
                </MuiAlert>
              </Snackbar>
            </Stack>
          </Container>
          <RootStyle>
            <DashboardNavbar
              onOpenSidebar={() => setOpen(true)}
              showAlert={showAlert}
              hideAlert={hideAlert}
            />
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle>
              <Outlet />
            </MainStyle>
          </RootStyle>
          <IdleTimeOutModal
            showModal={openIdle}
            handleLogout={handleLogout}
            handleClose={handleStillHere}
            remaining={remaining}
          />
        </>
      )}
    </>
  );
}
