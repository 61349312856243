import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useRef, useState, forwardRef } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
// material
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Container,
  Snackbar,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import MenuPopover from '../../components/MenuPopover';
//
import Auth from '../../services/Auth.service';
//
import account from '../../_mocks_/account';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MENU_OPTIONS = [
  {
    label: 'Change Password',
    icon: lockFill,
    linkTo: '/change-password'
  }
  // {
  //   label: 'Home',
  //   icon: homeFill,
  //   linkTo: '/'
  // },
  // {
  //   label: 'Profile',
  //   icon: personFill,
  //   linkTo: '#'
  // },
  // {
  //   label: 'Settings',
  //   icon: settings2Fill,
  //   linkTo: '#'
  // }
];

// ----------------------------------------------------------------------

AccountPopover.propTypes = {
  showAlert: PropTypes.func
};

export default function AccountPopover({ 
  showAlert
}) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const cookies = new Cookies();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setIsSubmitting(true);

    Auth.logout()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          cookies.remove('userData', { path: '/' });
          navigate('/login', { replace: true });
        } else {
          showAlert('error', data.responseMessage);
        }
        setIsSubmitting(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        setIsSubmitting(false);
      });
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    if (name === undefined || name === null) return '';

    const _name = name.trim();
    const children =
      _name.split(' ').length > 1
        ? `${_name.split(' ')[0][0]}${_name.split(' ')[1][0]}`
        : `${_name.split(' ')[0][0]}`;

    return {
      sx: {
        bgcolor: '#ed1c24' //stringToColor(name)
      },
      children: children.toUpperCase()
    };
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        {/* <Avatar src={account.photoURL} alt={global.userData.name} /> */}
        <Avatar {...stringAvatar(global.userData.name)} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {global.userData.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {global.userData.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <LoadingButton
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
            loading={isSubmitting}
          >
            Logout
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
}
