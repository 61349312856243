import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { ChangePassForm } from '../components/authentication/change-password';

// ----------------------------------------------------------------------

export default function ChangePassword() {
  return (
    <Page title="Change Password | RAF Billing">
      <Container maxWidth="sm">
        {/* <ContentStyle> */}
          <Stack sx={{ mb: 5 }}>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>

          <ChangePassForm />
        {/* </ContentStyle> */}
      </Container>
    </Page>
  );
}
