import http from '../http-common';

class AuthService {
  register(data) {
    const config = {
      responseType: 'application/json'
    };
    return http.post('/api/auth/register', data, config);
  }

  login(data) {
    const config = {
      responseType: 'application/json'
    };
    return http.post('/api/auth/login', data, config);
  }

  logout() {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.post('/api/auth/logout', '', config);
  }

  changePass(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.post('/api/auth/changePass', data, config);
  }

  getUser() {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.get('/api/auth/getUser', config);
  }

  getUsers() {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.get('/api/auth/getUsers', config);
  }

  deleteUser(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.post('/api/auth/deleteUser', data, config);
  }

  editUser(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.post('/api/auth/editUser', data, config);
  }
}

export default new AuthService();
