import http from '../../http-common';

class GenerateSOAService {
  getCustomerForSOA() {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.get('/api/billingAndInvoicing/getCustomerForSOA', config);
  }

  generateSOA(data) {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.post('/api/billingAndInvoicing/generateSOA', data, config);
  }
}

export default new GenerateSOAService();
