
import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
  } from '@mui/material';
import { useTheme } from '@mui/material/styles';

IdleTimeOutModal.propTypes = {
    showModal: PropTypes.bool, 
    handleClose: PropTypes.func, 
    handleLogout: PropTypes.func, 
    remaining: PropTypes.number,
};

export default function IdleTimeOutModal({
    showModal, 
    handleClose, 
    handleLogout, 
    remaining,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={showModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"You Have Been Idle!"}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Logging you out in {remaining} seconds
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button autoFocus onClick={handleLogout}>
                Logout
            </Button>
            <Button onClick={handleClose} autoFocus>
                Stay
            </Button>
            </DialogActions>
        </Dialog>
    )
}