import React, { useEffect, useState, forwardRef } from 'react';
import { filter } from 'lodash';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box,
  Stack,
  Snackbar,
  Alert,
  Link
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/_dashboard/table';
//
import OEXUploaderService from '../../services/accounts-detail-uploader/OEXUploader.service';
import UtilsService from '../../services/accounts-detail-uploader/Utils.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const moment = require('moment');

const TABLE_HEAD = [
  { id: 'carrier_code', label: 'Carrier Code', alignRight: false },
  { id: 'to_station', label: 'To Station', alignRight: false },
  { id: 'from_station', label: 'From Station', alignRight: false },
  { id: 'carrier_name', label: 'Carrier Name', alignRight: false },
  { id: 'from_country_name', label: 'From Country Name', alignRight: false },
  { id: 'to_country_name', label: 'To Country Name', alignRight: false },
  { id: 'docket_no', label: 'Docket #', alignRight: false },
  { id: 'mawb_type', label: 'MAWB Type', alignRight: false },
  { id: 'travel_id', label: 'Travel ID', alignRight: false },
  { id: 'manifest_date', label: 'Manifest Date', alignRight: false },
  { id: 'acct_code', label: 'Acct. Code', alignRight: false },
  { id: 'cubic_wgt_g', label: 'Cubic Wgt (G)', alignRight: true },
  { id: 'customs_val', label: 'Customs Val', alignRight: true },
  { id: 'sender_ref', label: 'Sender Ref.', alignRight: false },
  { id: 'alt_ref', label: 'Alt Ref.', alignRight: false },
  { id: 'connote_no', label: 'Connote #', alignRight: false },
  { id: 'wgt_g', label: 'Wgt (G)', alignRight: true },
  { id: 'no_of_items', label: 'No of Items', alignRight: true },
  { id: 'sender_ref2', label: 'Sender Ref 2', alignRight: false },
  { id: 'service_type', label: 'Service Type', alignRight: false },
  { id: 'customs_currency', label: 'Customs Currency', alignRight: false },
  { id: 'goods_desc', label: 'Goods Desc', alignRight: false },
  { id: 'origin_station', label: 'Origin Station', alignRight: false },
  { id: 'sender_ref3', label: 'Sender Ref 3', alignRight: false },
  { id: 'sender', label: 'Sender', alignRight: false },
  { id: 'sender_address1', label: 'Sender Address1', alignRight: false },
  { id: 'sender_country_code', label: 'Sender Country Code', alignRight: false },
  { id: 'sender_address2', label: 'Sender Address2', alignRight: false },
  { id: 'sender_country_name', label: 'Sender Country Name', alignRight: false },
  { id: 'sender_address3', label: 'Sender Address3', alignRight: false },
  { id: 'receiver_address2', label: 'Receiver Address2', alignRight: false },
  { id: 'receiver_address3', label: 'Receiver Address3', alignRight: false },
  { id: 'receiver_country_code', label: 'Receiver Country Code', alignRight: false },
  { id: 'receiver', label: 'Receiver', alignRight: false },
  { id: 'receiver_address4', label: 'Receiver Address4', alignRight: false },
  { id: 'receiver_country_name', label: 'Receiver Country Name', alignRight: false },
  { id: 'receiver_address1', label: 'Receiver Address1', alignRight: false },
  { id: 'receiver_address5', label: 'Receiver Address5', alignRight: false },
  { id: 'department', label: 'Department', alignRight: false },
  { id: 'pickup_date', label: 'Pickup Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_detail) => _detail.accountNo.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function OEXUploader() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('carrier_name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [uploading, setUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [uploadedData, setUploadedData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (selectedFiles && selectedFiles.length > 0) {
      upload();
    }
  }, [selectedFiles]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const downloadTemplate = () => {
    UtilsService.getUploadTemplate('OEX')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const link = document.createElement('a');
          link.href = data.responseData;
          link.setAttribute('download', 'OEX_Template.xlsx');
          document.body.appendChild(link);
          link.click();

          //showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showAlert('error', 'Could not download template!');
      });
  };

  const downloadLastUploaded = () => {
    UtilsService.getLastUploadedFile('OEX')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const link = document.createElement('a');
          link.href = data.responseData;
          link.setAttribute('download', 'OEX.xlsx');
          document.body.appendChild(link);
          link.click();

          //showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showAlert('error', 'Could not Download Last Uploaded File file!');
      });
  };

  const upload = () => {
    const currentFile = selectedFiles[0];
    setProgress(0);
    setUploadedData([]);
    setCurrentFile(currentFile);
    setUploading(true);

    OEXUploaderService.upload(currentFile, (event) => {
      setProgress(Math.round((90 * event.loaded) / event.total));
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          setUploadedData(data.responseData);
          showAlert('success', data.responseMessage);
          setProgress(100);
        } else {
          setUploadedData([]);
          showAlert('error', data.responseMessage);
          setProgress(100);
        }

        setUploading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setProgress(0);
        showAlert('error', 'Could not upload the file!');
        setCurrentFile(undefined);

        setUploading(false);
      });

    document.getElementById('btn-upload').value = '';
    setSelectedFiles(undefined);
  };

  const uploadAgain = () => {
    setProgress(0);
    setUploadedData([]);
    setCurrentFile(undefined);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = uploadedData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - uploadedData.length) : 0;

  const filteredDetails = applySortFilter(uploadedData, getComparator(order, orderBy), filterName);

  const isDetailNotFound = filteredDetails.length === 0;

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Page title={`OEX Uploader | ${process.env.REACT_APP_APP_NAME}`}>
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={hideAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>
        <Card className="card-upload">
          <div className="card-upload-container">
            {uploading ? (
              <LoadingButton className="btn-choose" loading variant="outlined" component="span">
                Upload
              </LoadingButton>
            ) : !currentFile ? (
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={selectFile}
                />
                {/* <span>Drop .xlsx here or</span>  */}
                <Button className="btn-choose" variant="outlined" component="span">
                  Choose File
                </Button>
              </label>
            ) : (
              <Button
                className="btn-again"
                variant="outlined"
                component="span"
                onClick={uploadAgain}
              >
                Upload Again
              </Button>
            )}
            {currentFile && (
              <Box className="mb25" display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                </Box>
              </Box>
            )}
            <Box width="100%" mr={1} mt={3}>
              <Link
                className="download-template"
                component="button"
                variant="body2"
                onClick={downloadTemplate}
              >
                Download Template
              </Link>
              {' or '}
              <Link
                className="download-template"
                component="button"
                variant="body2"
                onClick={downloadLastUploaded}
              >
                Download Last Uploaded File
              </Link>
            </Box>
          </div>
        </Card>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={uploadedData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredDetails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const {
                        carrier_code,
                        to_station,
                        from_station,
                        carrier_name,
                        from_country_name,
                        to_country_name,
                        docket_no,
                        mawb_type,
                        travel_id,
                        manifest_date,
                        acct_code,
                        cubic_wgt_g,
                        customs_val,
                        sender_ref,
                        alt_ref,
                        connote_no,
                        wgt_g,
                        no_of_items,
                        sender_ref2,
                        service_type,
                        customs_currency,
                        goods_desc,
                        origin_station,
                        sender_ref3,
                        sender,
                        sender_address1,
                        sender_country_code,
                        sender_address2,
                        sender_country_name,
                        sender_address3,
                        receiver_address2,
                        receiver_address3,
                        receiver_country_code,
                        receiver,
                        receiver_address4,
                        receiver_country_name,
                        receiver_address1,
                        receiver_address5,
                        department,
                        pickup_date,
                        statusCode,
                        statusMessage
                      } = row;
                      const isItemSelected = selected.indexOf(carrier_name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={i}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{carrier_code}</TableCell>
                          <TableCell align="left">{to_station}</TableCell>
                          <TableCell align="left">{from_station}</TableCell>
                          <TableCell align="left">{carrier_name}</TableCell>
                          <TableCell align="left">{from_country_name}</TableCell>
                          <TableCell align="left">{to_country_name}</TableCell>
                          <TableCell align="left">{docket_no}</TableCell>
                          <TableCell align="left">{mawb_type}</TableCell>
                          <TableCell align="left">{travel_id}</TableCell>
                          <TableCell align="left">
                            {moment(manifest_date).format('yyyy-MM-DD')}
                          </TableCell>
                          <TableCell align="left">{acct_code}</TableCell>
                          <TableCell align="right">
                            {parseFloat(cubic_wgt_g)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="right">
                            {parseFloat(customs_val)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="left">{sender_ref}</TableCell>
                          <TableCell align="left">{alt_ref}</TableCell>
                          <TableCell align="left">{connote_no}</TableCell>
                          <TableCell align="right">
                            {parseFloat(wgt_g)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="right">{no_of_items}</TableCell>
                          <TableCell align="left">{sender_ref2}</TableCell>
                          <TableCell align="left">{service_type}</TableCell>
                          <TableCell align="left">{customs_currency}</TableCell>
                          <TableCell align="left">{goods_desc}</TableCell>
                          <TableCell align="left">{origin_station}</TableCell>
                          <TableCell align="left">{sender_ref3}</TableCell>
                          <TableCell align="left">{sender}</TableCell>
                          <TableCell align="left">{sender_address1}</TableCell>
                          <TableCell align="left">{sender_country_code}</TableCell>
                          <TableCell align="left">{sender_address2}</TableCell>
                          <TableCell align="left">{sender_country_name}</TableCell>
                          <TableCell align="left">{sender_address3}</TableCell>
                          <TableCell align="left">{receiver_address2}</TableCell>
                          <TableCell align="left">{receiver_address3}</TableCell>
                          <TableCell align="left">{receiver_country_code}</TableCell>
                          <TableCell align="left">{receiver}</TableCell>
                          <TableCell align="left">{receiver_address4}</TableCell>
                          <TableCell align="left">{receiver_country_name}</TableCell>
                          <TableCell align="left">{receiver_address1}</TableCell>
                          <TableCell align="left">{receiver_address5}</TableCell>
                          <TableCell align="left">{department}</TableCell>
                          <TableCell align="left">
                            {moment(pickup_date).format('yyyy-MM-DD')}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(statusCode !== '200' && 'error') || 'success'}
                            >
                              {statusMessage}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={41} />
                    </TableRow>
                  )}
                </TableBody>
                {uploadedData.length > 0 && isDetailNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={41} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={uploadedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
