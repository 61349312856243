import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import Draggable from 'react-draggable';
// material
import {
  Stack,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

EditForm.propTypes = {
  toEditItem: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default function EditForm({ toEditItem, isSubmitting, onSubmit }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);

  const EditSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: ''
    },
    validationSchema: EditSchema,
    onSubmit: () => {
      handleOpenConfirm();
    }
  });

  const { errors, touched, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    setName(toEditItem.name);
    setFieldValue('name', toEditItem.name);

    setEmail(toEditItem.email);
    setFieldValue('email', toEditItem.email);
  }, []);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirmSubmit = () => {
    setOpenConfirm(false);

    const data = {
      userId: toEditItem.id,
      name: name,
      email: email
    };

    onSubmit(data);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ mb: 4 }}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Name"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value ? e.target.value : '');
                setFieldValue('name', e.target.value ? e.target.value : '');
              }}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email address"
              autoComplete="email"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value ? e.target.value : '');
                setFieldValue('email', e.target.value ? e.target.value : '');
              }}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Form>
      </FormikProvider>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', color: '#ed1c24' }} id="draggable-dialog-title">
          Edit User Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to save changes?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseConfirm}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirmSubmit} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
