import * as Yup from 'yup';
import React, { useEffect, useState, forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Autocomplete,
  Paper,
  Snackbar,
  Alert,
  Box,
  Card,
  CardActions,
  CardContent
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
//
import DomesticAttachmentService from '../../services/billing-and-invoicing/DomesticAttachment.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DomesticAttachment() {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [invDate, setInvDate] = useState([null, null]);
  const [DateFrom, setDateFrom] = useState('');
  const [DateTo, setDateTo] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState('');

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    DomesticAttachmentService.getCustomerForDomesticAttachment()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setCustomersList(data.responseData);
      })
      .catch((error) => {
        showAlert('error', error.message);
      });
  }, []);

  const RegisterSchema = Yup.object().shape({
    customer: Yup.string().required('Customer is required'),
    DateFrom: Yup.string().required('Invoice date from is required'),
    DateTo: Yup.string().required('Invoice date to is required')
  });

  const formik = useFormik({
    initialValues: {
      customer: '',
      DateFrom: '',
      DateTo: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      domesticAttachment();
      setSubmitting(false);
      // console.log(invDate);
      // if (invDate == 'Invalid Date') {
      //   touched.customer = true;
      //   errors.customer = 'Invalid invoice date';
      // } else {
      //   console.log(customer);
      //   console.log(value);
      //   // navigate('/dashboard', { replace: true });
      //   setSubmitting(false);
      // }
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setSubmitting
  } = formik;

  const RegisterSchemaSearch = Yup.object().shape({
    invoiceNo: Yup.string().required('Invoice number is required')
  });

  const formikSearch = useFormik({
    initialValues: {
      invoiceNo: ''
    },
    validationSchema: RegisterSchemaSearch,
    onSubmit: () => {
      searchInvoice();
      setSubmittingS(false);
      // console.log(invDate);
      // if (invDate == 'Invalid Date') {
      //   touched.customer = true;
      //   errors.customer = 'Invalid invoice date';
      // } else {
      //   console.log(customer);
      //   console.log(value);
      //   // navigate('/dashboard', { replace: true });
      //   setSubmitting(false);
      // }
    }
  });

  const errorsS = formikSearch.errors;
  const touchedS = formikSearch.touched;
  const handleSubmitS = formikSearch.handleSubmit;
  const isSubmittingS = formikSearch.isSubmitting;
  const getFieldPropsS = formikSearch.getFieldProps;
  const setFieldValueS = formikSearch.setFieldValue;
  const setSubmittingS = formikSearch.setSubmitting;

  const domesticAttachment = () => {
    const data = {
      accountNo: customer.accountNo,
      transDateFrom: moment(DateFrom).format('YYYY-MM-DD'),
      transDateTo: moment(DateTo).format('YYYY-MM-DD')
    };

    let urlFinal = '';
    let urlFinal2 = '';

    DomesticAttachmentService.domesticAttachment(data)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        console.log(data);

        if (data.responseCode === 200) {
          /** Invoice **/
          const byteCharacters = atob(data.responseData.pdfInvoice);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          console.log(byteArray);

          const blob = await new Blob([byteArray], { type: 'application/pdf' });
          const url = await window.URL.createObjectURL(blob);
          urlFinal = url;

          if (urlFinal !== undefined && urlFinal !== '') {
            // window.open(urlFinal,'_blank', 'toolbar=0,location=0,menubar=0');
            window.open(urlFinal,'_blank');
          }

          // /** Invoice Details **/
          // const byteCharacters2 = atob(data.responseData.pdfDetails);
          // const byteNumbers2 = new Array(byteCharacters2.length);
          // for (let i = 0; i < byteCharacters2.length; i++) {
          //   byteNumbers2[i] = byteCharacters2.charCodeAt(i);
          // }
          // const byteArray2 = new Uint8Array(byteNumbers2);
          // console.log(byteArray2);

          // const blob2 = await new Blob([byteArray2], { type: 'application/pdf' });
          // const url2 = await window.URL.createObjectURL(blob2);
          // urlFinal2 = url2;

          // if (urlFinal2 !== undefined && urlFinal2 !== '') {
          //   // window.open(urlFinal2,'_blank', 'toolbar=0,location=0,menubar=0');
          //   window.open(urlFinal2,'_blank');
          // }

          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
      });
  };

  const searchInvoice = () => {
    const data = {
      invoiceNo: invoiceNo
    };

    let urlFinal = '';
    let urlFinal2 = '';

    DomesticAttachmentService.searchInvoice(data)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        console.log(data);

        if (data.responseCode === 200) {
          /** Invoice **/
          const byteCharacters = atob(data.responseData.pdfInvoice);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          console.log(byteArray);

          const blob = await new Blob([byteArray], { type: 'application/pdf' });
          const url = await window.URL.createObjectURL(blob);
          urlFinal = url;

          if (urlFinal !== undefined && urlFinal !== '') {
            // window.open(urlFinal,'_blank', 'toolbar=0,location=0,menubar=0');
            window.open(urlFinal,'_blank');
          }

          /** Invoice Details **/
          const byteCharacters2 = atob(data.responseData.pdfDetails);
          const byteNumbers2 = new Array(byteCharacters2.length);
          for (let i = 0; i < byteCharacters2.length; i++) {
            byteNumbers2[i] = byteCharacters2.charCodeAt(i);
          }
          const byteArray2 = new Uint8Array(byteNumbers2);
          console.log(byteArray2);

          const blob2 = await new Blob([byteArray2], { type: 'application/pdf' });
          const url2 = await window.URL.createObjectURL(blob2);
          urlFinal2 = url2;

          if (urlFinal2 !== undefined && urlFinal2 !== '') {
            // window.open(urlFinal2,'_blank', 'toolbar=0,location=0,menubar=0');
            window.open(urlFinal2,'_blank');
          }

          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
      });
  };

  return (
    <Page title={`Domestic Attachment | ${process.env.REACT_APP_APP_NAME}`}>
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={hideAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>
      </Container>
      <Container maxWidth="md">
        <Card sx={{ minWidth: 275 }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent>
                <Stack spacing={2}>
                  <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                    <Autocomplete
                      freeSolo
                      fullWidth
                      options={customersList}
                      getOptionLabel={(option) => option.companyName}
                      onChange={(e, value) => {
                        //console.log(value);
                        setCustomer(value);
                        setFieldValue('customer', value.companyName ? value.companyName : '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customer"
                          name="customer"
                          {...getFieldProps('customer')}
                          error={Boolean(touched.customer && errors.customer)}
                          helperText={touched.customer && errors.customer}
                        />
                      )}
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateRangePicker
                        startText="Date From"
                        endText="Date To"
                        value={invDate}
                        onChange={(value) => {
                          //console.log(value);
                          setInvDate(value);
                          setDateFrom(value[0] === null ? '' : value[0]);
                          setDateTo(value[1] === null ? '' : value[1]);
                          setFieldValue('DateFrom', value[0] === null ? '' : value[0]);
                          setFieldValue('DateTo', value[1] === null ? '' : value[1]);
                        }}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <Stack
                              direction={{ xs: 'column', sm: 'row', width: '100%' }}
                              spacing={2}
                            >
                              <TextField
                                {...startProps}
                                fullWidth
                                name="DateFrom"
                                {...getFieldProps('DateFrom')}
                                error={Boolean(touched.DateFrom && errors.DateFrom)}
                                helperText={touched.DateFrom && errors.DateFrom}
                              />
                              <TextField
                                {...endProps}
                                fullWidth
                                name="DateTo"
                                {...getFieldProps('DateTo')}
                                error={Boolean(touched.DateTo && errors.DateTo)}
                                helperText={touched.DateTo && errors.DateTo}
                              />
                            </Stack>
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Stack>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="outlined"
                  loading={isSubmitting}
                  sx={{ maxWidth: '200px', alignSelf: 'center' }}
                >
                  Generate
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Container>
      { <Container maxWidth="md" sx={{ marginTop: '20px' }}>
        <Card sx={{ minWidth: 275 }}>
          <FormikProvider value={formikSearch}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmitS}>
              <CardContent>
                <Stack spacing={2}>
                  <TextField
                    id="invoiceNo"
                    name="invoiceNo"
                    label="Invoice Number"
                    type="search"
                    value={invoiceNo}
                    onChange={(e) => {
                      //console.log(e);
                      setInvoiceNo(e.target.value);
                      setFieldValueS('invoiceNo', e.target.value);
                    }}
                    //{...getFieldPropsS('invoiceNo')}
                    error={Boolean(touchedS.invoiceNo && errorsS.invoiceNo)}
                    helperText={touchedS.invoiceNo && errorsS.invoiceNo}
                  />
                </Stack>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="outlined"
                  loading={isSubmittingS}
                  sx={{ maxWidth: '200px', alignSelf: 'center' }}
                >
                  Search Invoice
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Container> }
    </Page>
  );
}
