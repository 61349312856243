import axios from 'axios';

let appConfig = require('./app-config');
const env = process.env.BUILD_ENV ? process.env.BUILD_ENV : process.env.NODE_ENV;
appConfig = appConfig[env];

// ----------------------------------------------------------------------

export default axios.create({
  baseURL: appConfig.api.baseUrl,
  headers: {
    'Content-type': 'application/json'
  }
});
