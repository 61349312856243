import http from '../http-common';

class InquiryService {
  inquireByHAWB(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.post('/api/inquiry/inquireByHAWB', data, config);
  }
}

export default new InquiryService();
