import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Alert,
  IconButton,
  Collapse,
  TableFooter,
  MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// components
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import MiscListHead from './MiscListHead';
import MiscListToolbar from './MiscListToolbar';
import MiscMoreMenu from './MiscMoreMenu';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'miscName', label: 'Misc. Name', alignRight: false },
  { id: 'miscValue', label: 'Misc. Value', alignRight: true },
  { id: 'miscVat', label: 'With VAT', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_miscellaneous) => _miscellaneous.miscName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Miscellaneous({
  miscList,
  onAddMisc,
  onEditMisc,
  onDeleteMisc,
  disableProceed
}) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [miscMode, setMiscMode] = useState('ADD');
  const [miscName, setMiscName] = useState('');
  const [miscValue, setMiscValue] = useState('0.00');
  const [miscVat, setMiscVat] = useState('N');
  const [editMiscName, setEditMiscName] = useState('');
  const [miscNameEdit, setMiscNameEdit] = useState('');
  const [miscValueEdit, setMiscValueEdit] = useState('0.00');
  const [miscVatEdit, setMiscVatEdit] = useState('N');

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertType, setAlertType] = useState('info');
  const [alertMessage, setAlertMessage] = useState('');

  const vatOption = [
    {
      value: 'Y',
      label: 'Yes'
    },
    {
      value: 'N',
      label: 'No'
    }
  ];

  const openAlert = (alertType, alertMessage) => {
    setAlertType(alertType);
    setAlertMessage(alertMessage);
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertType('info');
    setAlertMessage('');
    setAlertOpen(false);
  };

  useEffect(() => {
    if (alertOpen) {
      const timeId = setTimeout(() => {
        closeAlert();
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [alertOpen]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = miscList.map((n) => n.miscName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - miscList.length) : 0;

  const filteredMiscellaneous = applySortFilter(
    miscList,
    getComparator(order, orderBy),
    filterName
  );

  const isMiscellaneousNotFound = filteredMiscellaneous.length === 0;

  const MiscSchema = Yup.object().shape({
    miscMode: Yup.string(),
    miscName: Yup.string().when('miscMode', {
      is: (value) => value === 'ADD',
      then: Yup.string()
        .required('This field is requried')
        .max(100, 'Maximum number of 100 characters')
    }),
    miscValue: Yup.string().when('miscMode', {
      is: (value) => value === 'ADD',
      then: Yup.string()
        .required('This field is requried')
        .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, 'Not a valid value')
    }),
    miscNameEdit: Yup.string().when('miscMode', {
      is: (value) => value === 'EDIT',
      then: Yup.string()
        .required('This field is requried')
        .max(100, 'Maximum number of 100 characters')
    }),
    miscValueEdit: Yup.string().when('miscMode', {
      is: (value) => value === 'EDIT',
      then: Yup.string()
        .required('This field is requried')
        .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, 'Not a valid value')
    })
  });

  const formik = useFormik({
    initialValues: {
      miscMode: 'ADD',
      miscName: '',
      miscValue: '0.00',
      miscNameEdit: '',
      miscValueEdit: '0.00'
    },
    validationSchema: MiscSchema,
    onSubmit: () => {
      if (miscMode === 'ADD') {
        const getMiscList = miscList.find((misc) => misc.miscName === miscName);
        if (!getMiscList) {
          onAddMisc(miscName, miscValue, miscVat);
          setMiscName('');
          setFieldValue('miscName', '');
          setMiscValue('0.00');
          setFieldValue('miscValue', '0.00');
          setMiscVat('N');
          formik.setTouched({}, false);
          openAlert('success', 'New misc. successfully added.');
        } else {
          openAlert('error', 'Misc. already added.');
        }
      } else {
        const getMiscListEdit = miscList.find((misc) => misc.miscName === miscNameEdit);
        if (!getMiscListEdit || (getMiscListEdit && getMiscListEdit.miscName === editMiscName)) {
          onEditMisc(editMiscName, miscNameEdit, miscValueEdit, miscVatEdit);
          setMiscMode('ADD');
          setFieldValue('miscMode', 'ADD');
          setMiscNameEdit('');
          setFieldValue('miscNameEdit', '');
          setMiscValueEdit('0.00');
          setFieldValue('miscValueEdit', '0.00');
          setMiscVatEdit('N');
          setEditMiscName('');
          formik.setTouched({}, false);
          openAlert('success', 'Misc. successfully updated.');
        } else {
          openAlert('error', 'Misc. already exists.');
        }
      }
      disableProceed(false);
    }
  });

  const { errors, touched, handleSubmit, setFieldValue, setErrors } = formik;

  const onEditClick = (misc) => {
    console.log(misc);
    setMiscMode('EDIT');
    setFieldValue('miscMode', 'EDIT');
    setMiscNameEdit(misc.miscName);
    setFieldValue('miscNameEdit', misc.miscName);
    setMiscValueEdit(misc.miscValue);
    setFieldValue('miscValueEdit', misc.miscValue);
    setMiscVatEdit(misc.miscVat);
    setEditMiscName(misc.miscName);
    disableProceed(true);
  };

  const onDeleteClick = (misc) => {
    onDeleteMisc(misc.miscName);
    openAlert('success', `Misc. ${misc.miscName} successfully deleted.`);
  };

  const onUpdateClick = () => {
    handleSubmit();
  };

  const onCancelClick = () => {
    setMiscMode('ADD');
    setFieldValue('miscMode', 'ADD');
    setMiscNameEdit('');
    setFieldValue('miscNameEdit', '');
    setMiscValueEdit('0.00');
    setFieldValue('miscValueEdit', '0.00');
    setMiscVatEdit('N');
    setEditMiscName('');
    formik.setTouched({}, false);
    disableProceed(false);
  };

  const totalMisc = (misc) => {
    return misc.map(({ miscValue }) => parseFloat(miscValue)).reduce((sum, i) => sum + i, 0);
  };

  return (
    <Container>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            alignItems="center"
            style={{ alignItems: 'stretch' }}
            mb={2}
          >
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              style={{ padding: '5px', width: '100%' }}
              spacing={2}
            >
              <TextField
                fullWidth
                id="miscName"
                name="miscName"
                label="Misc. Name"
                type="text"
                value={miscName}
                disabled={miscMode === 'EDIT'}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => {
                  setMiscName(e.target.value);
                  setFieldValue('miscName', e.target.value);
                }}
                error={Boolean(touched.miscName && errors.miscName)}
                helperText={touched.miscName && errors.miscName}
              />
              <TextField
                fullWidth
                id="miscValue"
                name="miscValue"
                label="Misc. Value"
                type="number"
                value={miscValue}
                disabled={miscMode === 'EDIT'}
                inputProps={{ maxLength: 10, style: { textAlign: 'right' } }}
                onChange={(e) => {
                  setMiscValue(e.target.value);
                  setFieldValue('miscValue', e.target.value);
                }}
                error={Boolean(touched.miscValue && errors.miscValue)}
                helperText={touched.miscValue && errors.miscValue}
              />
              <TextField
                fullWidth
                id="miscVat"
                name="miscVat"
                select
                label="With VAT"
                defaultValue="N"
                value={miscVat}
                disabled={miscMode === 'EDIT'}
                onChange={(e) => {
                  setMiscVat(e.target.value);
                }}
                sx={{ maxWidth: { xs: '100%', sm: '100%', md: '100px' } }}
              >
                {vatOption &&
                  vatOption.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>
            <Stack width={{ xs: '100%', sm: '100%', md: '200px' }} style={{ padding: '5px' }}>
              <Button
                style={{ width: '100%', height: '54px' }}
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                type="submit"
                disabled={miscMode === 'EDIT'}
              >
                Add Misc.
              </Button>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>

      <Collapse in={alertOpen}>
        <Alert
          severity={alertType}
          variant="outlined"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={closeAlert}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alertMessage}
        </Alert>
      </Collapse>

      <Card>
        {/* <MiscListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <MiscListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={miscList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredMiscellaneous
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const { miscName, miscValue, miscVat } = row;
                    const isItemSelected = selected.indexOf(miscName) !== -1;

                    return (
                      <TableRow hover key={i} tabIndex={-1}>
                        <TableCell align="left" style={{ verticalAlign: 'top' }}>
                          {miscMode === 'EDIT' && editMiscName === row.miscName ? (
                            <TextField
                              fullWidth
                              id="miscNameEdit"
                              name="miscNameEdit"
                              label=""
                              type="text"
                              value={miscNameEdit}
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                setMiscNameEdit(e.target.value);
                                setFieldValue('miscNameEdit', e.target.value);
                              }}
                              error={Boolean(touched.miscNameEdit && errors.miscNameEdit)}
                              helperText={touched.miscNameEdit && errors.miscNameEdit}
                            />
                          ) : (
                            miscName
                          )}
                        </TableCell>
                        <TableCell align="right" style={{ verticalAlign: 'top' }}>
                          {miscMode === 'EDIT' && editMiscName === row.miscName ? (
                            <TextField
                              fullWidth
                              id="miscValueEdit"
                              name="miscValueEdit"
                              label=""
                              type="number"
                              value={miscValueEdit}
                              inputProps={{ maxLength: 10, style: { textAlign: 'right' } }}
                              onChange={(e) => {
                                setMiscValueEdit(e.target.value);
                                setFieldValue('miscValueEdit', e.target.value);
                              }}
                              error={Boolean(touched.miscValueEdit && errors.miscValueEdit)}
                              helperText={touched.miscValueEdit && errors.miscValueEdit}
                            />
                          ) : (
                            parseFloat(miscValue)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                          )}
                        </TableCell>
                        <TableCell align="center" style={{ verticalAlign: 'top' }}>
                          {miscMode === 'EDIT' && editMiscName === row.miscName ? (
                            <TextField
                              fullWidth
                              id="miscVatEdit"
                              name="miscVatEdit"
                              select
                              label="With VAT"
                              defaultValue="N"
                              value={miscVatEdit}
                              onChange={(e) => {
                                setMiscVatEdit(e.target.value);
                              }}
                              style={{ maxWidth: '100px' }}
                            >
                              {vatOption &&
                                vatOption.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          ) : miscVat === 'Y' ? (
                            'Yes'
                          ) : (
                            'No'
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {(miscMode === 'EDIT' && editMiscName === row.miscName) ||
                          miscMode === 'ADD' ? (
                            <MiscMoreMenu
                              item={row}
                              mode={miscMode}
                              onEditClick={onEditClick}
                              onDeleteClick={onDeleteClick}
                              onUpdateClick={onUpdateClick}
                              onCancelClick={onCancelClick}
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
              {/* {isMiscellaneousNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              {/* <TableFooter>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{ fontSize: '14px', fontWeight: 'bold', verticalAlign: 'top' }}
                  >
                    Total Mics.
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ fontSize: '14px', fontWeight: 'bold', verticalAlign: 'top' }}
                  >
                    {parseFloat(totalMisc(miscList))
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </TableCell>
                  <TableCell align="right">
                    <></>
                  </TableCell>
                </TableRow>
              </TableFooter> */}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={miscList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
