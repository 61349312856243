import http from '../http-common';

class DashboardService {
  getDashboardCounts(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };
    return http.get('/api/dashboard/getDashboardCounts', config);
  }
}

export default new DashboardService();
