import http from '../../http-common';

class UtilsService {

  getUploadTemplate(template) {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.get(`/api/utils/getUploadTemplate/${template}`, config);
  }

  getLastUploadedFile(template) {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.get(`/api/utils/getLastUploadedFile/${template}`, config);
  }
}

export default new UtilsService();
