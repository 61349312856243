import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Inquiry from './pages/Inquiry';
import ChangePassword from './pages/ChangePassword';
import NotFound from './pages/Page404';
// Accounts Detail Uploader
import CustomerUploader from './pages/accounts-detail-uploader/CustomerUploader';
import BrokerageUploader from './pages/accounts-detail-uploader/BrokerageUploader';
import SPUploader from './pages/accounts-detail-uploader/SPUploader';
import OEXUploader from './pages/accounts-detail-uploader/OEXUploader';
import PaymentsUploader from './pages/accounts-detail-uploader/PaymentsUploader';
import MAWBUploader from './pages/accounts-detail-uploader/MAWBUploader';
import DetailsUploader from './pages/accounts-detail-uploader/DetailsUploader';
import RateCardsUploader from './pages/accounts-detail-uploader/RateCardsUploader';
import BCCUploader from './pages/accounts-detail-uploader/BCCUploader';
import StaffUploader from './pages/accounts-detail-uploader/StaffUploader';
// Billing And Invoicing
import GenerateInvoice from './pages/billing-and-invoicing/GenerateInvoice';
import SPProfitabilityReport from './pages/billing-and-invoicing/SPProfitabilityReport';
import GenerateSOA from './pages/billing-and-invoicing/GenerateSOA';
import DomesticAttachment from './pages/billing-and-invoicing/DomesticAttachment';
// File Maintenance
import PartyFiles from './pages/file-maintenance/PartyFiles';
import CustomerTariff from './pages/file-maintenance/CustomerTariff';
import VendorsTariff from './pages/file-maintenance/VendorsTariff';
import Countries from './pages/file-maintenance/Countries';
import IATACurrencyRates from './pages/file-maintenance/IATACurrencyRates';
import FuelCurrencyRates from './pages/file-maintenance/FuelCurrencyRates';
import ESCCurrencyRates from './pages/file-maintenance/ESCCurrencyRates';
import DestiRatesBlueprint from './pages/file-maintenance/DestiRatesBlueprint';
import CustomizeDestiRatesBlueprint from './pages/file-maintenance/CustomizeDestiRatesBlueprint';
import CostingBlueprint from './pages/file-maintenance/CostingBlueprint';
import ChargesCodes from './pages/file-maintenance/ChargesCodes';
import User from './pages/file-maintenance/User';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace /> },
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'inquiry', element: <Inquiry /> },
        { path: 'change-password', element: <ChangePassword /> },
        // Accounts Detail Uploader
        { path: 'accounts-detail-uploader/customer-uploader', element: <CustomerUploader /> },
        { path: 'accounts-detail-uploader/brokerage-uploader', element: <BrokerageUploader /> },
        { path: 'accounts-detail-uploader/sp-uploader', element: <SPUploader /> },
        { path: 'accounts-detail-uploader/oex-uploader', element: <OEXUploader /> },
        { path: 'accounts-detail-uploader/payments-uploader', element: <PaymentsUploader /> },
        { path: 'accounts-detail-uploader/mawb-uploader', element: <MAWBUploader /> },
        { path: 'accounts-detail-uploader/details-uploader', element: <DetailsUploader /> },
        { path: 'accounts-detail-uploader/rate-cards-uploader', element: <RateCardsUploader /> },
        { path: 'accounts-detail-uploader/bcc-uploader', element: <BCCUploader /> },
        { path: 'accounts-detail-uploader/staff-uploader', element: <StaffUploader /> },
        // Billing And Invoicing
        { path: 'billing-and-invoicing/generate-invoice', element: <GenerateInvoice /> },
        {
          path: 'billing-and-invoicing/sp-profitability-report',
          element: <SPProfitabilityReport />
        },
        { path: 'billing-and-invoicing/generate-soa', element: <GenerateSOA /> },
        { path: 'billing-and-invoicing/domestic-attachment', element: <DomesticAttachment /> },
        // File Maintenance
        // { path: 'file-maintenance/partyfiles', element: <PartyFiles /> },
        // { path: 'file-maintenance/customertariff', element: <CustomerTariff /> },
        // { path: 'file-maintenance/vendorstariff', element: <VendorsTariff /> },
        { path: 'file-maintenance/countries', element: <Countries /> },
        { path: 'file-maintenance/iatacurrencyrates', element: <IATACurrencyRates /> },
        { path: 'file-maintenance/fuelcurrencyrates', element: <FuelCurrencyRates /> },
        { path: 'file-maintenance/esccurrencyrates', element: <ESCCurrencyRates /> },
        { path: 'file-maintenance/desti-rates-blueprint', element: <DestiRatesBlueprint /> },
        {
          path: 'file-maintenance/customize-desti-rates-blueprint',
          element: <CustomizeDestiRatesBlueprint />
        },
        { path: 'file-maintenance/costing-blueprint', element: <CostingBlueprint /> },
        // { path: 'file-maintenance/chargescodes', element: <ChargesCodes /> },
        { path: 'file-maintenance/user', element: <User /> },
        { path: '404', element: <NotFound /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <NotFound /> }
      ]
    },
    { path: '*', element: <NotFound replace /> }
  ]);
}
