import React, { useEffect, useState, forwardRef } from 'react';
import { filter } from 'lodash';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box,
  Stack,
  Snackbar,
  Alert,
  Link
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/_dashboard/rate-cards-uploader';
//
import RateCardsUploaderService from '../../services/accounts-detail-uploader/RateCardsUploader.service';
import UtilsService from '../../services/accounts-detail-uploader/Utils.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TABLE_HEAD = [
  { id: 'mawb', label: 'MAWB', alignRight: false },
  { id: 'hawb', label: 'HAWB', alignRight: false },
  { id: 'charges', label: 'Charges', alignRight: true },
  { id: 'iata', label: 'IATA', alignRight: true },
  { id: 'amountInPHP', label: 'Amount in PHP', alignRight: true },
  { id: 'delivery', label: 'Delivery', alignRight: true },
  { id: 'storageInPHP', label: 'Storage in PHP', alignRight: true },
  { id: 'totamtInPHP', label: 'Total Amount in PHP', alignRight: true },
  { id: 'status', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_detail) => _detail.mawb.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function RateCardsUploader() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('mawb');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [uploading, setUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [uploadedData, setUploadedData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (selectedFiles && selectedFiles.length > 0) {
      upload();
    }
  }, [selectedFiles]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const downloadTemplate = () => {
    UtilsService.getUploadTemplate('Billing_Rate_Card')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const link = document.createElement('a');
          link.href = data.responseData;
          link.setAttribute('download', 'Billing_Rate_Card_Template.xlsx');
          document.body.appendChild(link);
          link.click();

          //showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showAlert('error', 'Could not download template!');
      });
  };

  const downloadLastUploaded = () => {
    UtilsService.getLastUploadedFile('Billing_Rate_Card')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const link = document.createElement('a');
          link.href = data.responseData;
          link.setAttribute('download', 'Billing_Rate_Card.xlsx');
          document.body.appendChild(link);
          link.click();

          //showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showAlert('error', 'Could not Download Last Uploaded File file!');
      });
  };

  const upload = () => {
    const currentFile = selectedFiles[0];
    setProgress(0);
    setUploadedData([]);
    setCurrentFile(currentFile);
    setUploading(true);

    RateCardsUploaderService.upload(currentFile, (event) => {
      setProgress(Math.round((90 * event.loaded) / event.total));
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          setUploadedData(data.responseData);
          showAlert('success', data.responseMessage);
          setProgress(100);
        } else {
          setUploadedData([]);
          showAlert('error', data.responseMessage);
          setProgress(100);
        }

        setUploading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setProgress(0);
        showAlert('error', 'Could not upload the file!');
        setCurrentFile(undefined);

        setUploading(false);
      });

    document.getElementById('btn-upload').value = '';
    setSelectedFiles(undefined);
  };

  const uploadAgain = () => {
    setProgress(0);
    setUploadedData([]);
    setCurrentFile(undefined);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = uploadedData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - uploadedData.length) : 0;

  const filteredRateCards = applySortFilter(
    uploadedData,
    getComparator(order, orderBy),
    filterName
  );

  const isRateCardNotFound = filteredRateCards.length === 0;

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Page title={`Details Uploader | ${process.env.REACT_APP_APP_NAME}`}>
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={hideAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>
        <Card className="card-upload">
          <div className="card-upload-container">
            {uploading ? (
              <LoadingButton className="btn-choose" loading variant="outlined" component="span">
                Upload
              </LoadingButton>
            ) : !currentFile ? (
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={selectFile}
                />
                {/* <span>Drop .xlsx here or</span>  */}
                <Button className="btn-choose" variant="outlined" component="span">
                  Choose File
                </Button>
              </label>
            ) : (
              <Button
                className="btn-again"
                variant="outlined"
                component="span"
                onClick={uploadAgain}
              >
                Upload Again
              </Button>
            )}
            {currentFile && (
              <Box className="mb25" display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                </Box>
              </Box>
            )}
            <Box width="100%" mr={1} mt={3}>
              <Link
                className="download-template"
                component="button"
                variant="body2"
                onClick={downloadTemplate}
              >
                Download Template
              </Link>
              {' or '}
              <Link
                className="download-template"
                component="button"
                variant="body2"
                onClick={downloadLastUploaded}
              >
                Download Last Uploaded File
              </Link>
            </Box>
          </div>
        </Card>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={uploadedData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredRateCards
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const {
                        mawb,
                        hawb,
                        charges,
                        iata,
                        amountInPHP,
                        delivery,
                        storageInPHP,
                        totamtInPHP,
                        statusCode,
                        statusMessage
                      } = row;
                      const isItemSelected = selected.indexOf(mawb) !== -1;

                      return (
                        <TableRow
                          hover
                          key={i}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{mawb}</TableCell>
                          <TableCell align="left">{hawb}</TableCell>
                          <TableCell align="right">
                            {parseFloat(charges)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="right">
                            {parseFloat(iata)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="right">
                            {parseFloat(amountInPHP)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="right">
                            {parseFloat(delivery)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="right">
                            {parseFloat(storageInPHP)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="right">
                            {parseFloat(totamtInPHP)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(statusCode !== '200' && 'error') || 'success'}
                            >
                              {statusMessage}
                            </Label>
                          </TableCell>

                          {/* <TableCell align="right">
                            <MoreMenu />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
                {uploadedData.length > 0 && isRateCardNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={uploadedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
