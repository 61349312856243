import React, { forwardRef, useEffect, useState } from 'react';
// material
import { Box, Grid, Container, Typography, Snackbar, Alert, Stack } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppCustomers,
  AppSPInvoices,
  AppOEXInvoices,
  AppPayments,
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
  AppSummaryCount
} from '../components/_dashboard/app';
import { ThreeCircles } from 'react-loader-spinner';
import DashboardService from 'src/services/Dashboard.service';
// iconify
import CustomersIcon from '@iconify/icons-ant-design/team-outlined';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DashboardApp() {
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [dashboardCounts, setDashboardCounts] = useState(null);

  useEffect(async () => {
    setLoading(true);
    setDashboardCounts(null);
    await DashboardService.getDashboardCounts()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setDashboardCounts(data.responseData);
        setLoading(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        setLoading(false);
      });
  }, []);

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <>
      <ThreeCircles
        height="100"
        width="100"
        color="#ed1c24"
        wrapperStyle={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '99999',
          textAlign: 'center'
        }}
        wrapperClass=""
        visible={loading}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
      <Page title="Dashboard | RAF Billing">
        {/* <Container>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={hideAlert}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </MuiAlert>
            </Snackbar>
          </Stack>
        </Container>
        {!loading && (
          <Container maxWidth="xl">
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">Hi, Welcome Back!</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppCustomers count={dashboardCounts?.customers} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AppSPInvoices count={dashboardCounts?.sp_invoices} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AppOEXInvoices count={dashboardCounts?.oex_invoices} />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AppPayments count={dashboardCounts?.payments} />
              </Grid>
            </Grid>
          </Container>
        )} */}
      </Page>
    </>
  );
}
