import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState, forwardRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import Draggable from 'react-draggable';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import { EditForm } from '../../components/authentication/edit';
import { RegisterForm } from '../../components/authentication/register';
//
// import USERLIST from '../../_mocks_/user';
import Auth from '../../services/Auth.service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'userAccess', label: 'User Access', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function DelPaperComponent(props) {
  return (
    <Draggable handle="#del-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function EditPaperComponent(props) {
  return (
    <Draggable handle="#edit-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function AddPaperComponent(props) {
  return (
    <Draggable handle="#add-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [openDelConfirm, setOpenDelConfirm] = useState(false);
  const [toDelItem, setToDelItem] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [toEditItem, setToEditItem] = useState(null);
  const [editIsSubmitting, setEditIsSubmitting] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [addIsSubmitting, setAddIsSubmitting] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    Auth.getUsers()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          setUsers(data.responseData);
        } else {
          setUsers([]);
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
      });
  };

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  /** Delete **/
  const handleOpenDelConfirm = (row) => {
    setToDelItem(row);
    setOpenDelConfirm(true);
  };

  const handleCloseDelConfirm = () => {
    setToDelItem(null);
    setOpenDelConfirm(false);
  };

  const handleConfirmDel = () => {
    setIsDeleting(true);

    const data = {
      userId: toDelItem.id
    };

    Auth.deleteUser(data)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          getUsers();
          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }

        setIsDeleting(false);
        setOpenDelConfirm(false);
        setToDelItem(null);
      })
      .catch((error) => {
        setIsDeleting(false);
        setOpenDelConfirm(false);
        setToDelItem(null);
        showAlert('error', error.message);
      });
  };

  /** Edit **/
  const handleOpenEditUser = (row) => {
    setToEditItem(row);
    setOpenEditUser(true);
  };

  const handleCloseEditUser = () => {
    setToEditItem(null);
    setOpenEditUser(false);
  };

  const handleConfirmEdit = (data) => {
    setEditIsSubmitting(true);

    Auth.editUser(data)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          getUsers();
          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }

        setToEditItem(null);
        setEditIsSubmitting(false);
        setOpenEditUser(false);
      })
      .catch((error) => {
        setToEditItem(null);
        setEditIsSubmitting(false);
        setOpenEditUser(false);
        showAlert('error', error.message);
      });
  };

  /** Add **/
  const handleOpenAddUser = (row) => {
    setOpenAddUser(true);
  };

  const handleCloseAddUser = () => {
    setOpenAddUser(false);
  };

  const handleConfirmAdd = (data) => {
    setAddIsSubmitting(true);

    Auth.register(data)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          getUsers();
          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }

        setAddIsSubmitting(false);
        setOpenAddUser(false);
      })
      .catch((error) => {
        setAddIsSubmitting(false);
        setOpenAddUser(false);
        showAlert('error', error.message);
      });
  };

  /** **/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users
        .filter((item) => item.user_access !== 1 && item.deleted_at === null)
        .map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    if (name === undefined || name === null) return '';

    const _name = name.trim();
    const children =
      _name.split(' ').length > 1
        ? `${_name.split(' ')[0][0]}${_name.split(' ')[1][0]}`
        : `${_name.split(' ')[0][0]}`;

    return {
      sx: {
        bgcolor: stringToColor(name) //'#ed1c24'
      },
      children: children.toUpperCase()
    };
  };

  return (
    <Page title="User | RAF Billing">
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={hideAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>
      </Container>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {/* <Typography variant="h4" gutterBottom>
            User
          </Typography> */}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            onClick={handleOpenAddUser}
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={
                    users.filter((item) => item.user_access !== 1 && item.deleted_at === null)
                      .length
                  }
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        name,
                        email,
                        user_access,
                        created_at,
                        updated_at,
                        deleted_at,
                        access_type,
                        access_modules
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            {user_access !== 1 && deleted_at === null ? (
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            ) : (
                              <Checkbox disabled />
                            )}
                          </TableCell> */}
                          <TableCell component="th" scope="row" padding="normal">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Avatar {...stringAvatar(name)} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{access_type}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(deleted_at === null && 'success') || 'error'}
                            >
                              {sentenceCase(deleted_at === null ? 'active' : 'deleted')}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            {deleted_at === null && (
                              <UserMoreMenu
                                item={row}
                                onDeleteClick={handleOpenDelConfirm}
                                onEditClick={handleOpenEditUser}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/** Delete Confirmation Dialog **/}
      <Dialog
        open={openDelConfirm}
        onClose={handleCloseDelConfirm}
        PaperComponent={DelPaperComponent}
        aria-labelledby="del-draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', color: '#ed1c24' }} id="del-draggable-dialog-title">
          Delete User Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirming this action will delete this user and will not be used for login.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDelConfirm}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isDeleting}
            onClick={handleConfirmDel}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/** Edit Dialog **/}
      <Dialog
        open={openEditUser}
        onClose={handleCloseEditUser}
        PaperComponent={EditPaperComponent}
        aria-labelledby="edit-draggable-dialog-title"
        fullWidth={true}
      >
        <DialogTitle style={{ cursor: 'move' }} id="edit-draggable-dialog-title">
          <Stack direction="row">
            Edit User
            <Box sx={{ flexGrow: 1 }} />
            <Button autoFocus onClick={handleCloseEditUser}>
              Cancel
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent dividers={true}>
          <EditForm
            toEditItem={toEditItem}
            isSubmitting={editIsSubmitting}
            onSubmit={handleConfirmEdit}
          />
        </DialogContent>
      </Dialog>

      {/** Add Dialog **/}
      <Dialog
        open={openAddUser}
        onClose={handleCloseAddUser}
        PaperComponent={AddPaperComponent}
        aria-labelledby="add-draggable-dialog-title"
        fullWidth={true}
      >
        <DialogTitle style={{ cursor: 'move' }} id="edit-draggable-dialog-title">
          <Stack direction="row">
            Add New User
            <Box sx={{ flexGrow: 1 }} />
            <Button autoFocus onClick={handleCloseAddUser}>
              Cancel
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent dividers={true}>
          <RegisterForm isSubmitting={addIsSubmitting} onSubmit={handleConfirmAdd} />
        </DialogContent>
      </Dialog>
    </Page>
  );
}
