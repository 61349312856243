import http from '../../http-common';

class SPProfitabilityReport {
  getCustomerForSPPR() {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.get('/api/billingAndInvoicing/getCustomerForSPPR', config);
  }

  generateSPPR(data) {
    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
      },
      responseType: 'application/json',
    };

    return http.post('/api/billingAndInvoicing/generateSPPR', data, config);
  }
}

export default new SPProfitabilityReport();
