import React, { useEffect, useState, forwardRef } from 'react';
import * as Yup from 'yup';
import { filter } from 'lodash';
import moment from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';
import { ThreeCircles } from 'react-loader-spinner';
// material
import {
  Card,
  Table,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box,
  Stack,
  Snackbar,
  Alert,
  Link
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../components/_dashboard/table';
//
import InquiryService from '../services//Inquiry.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TABLE_HEAD = [
  { id: 'mawb', label: 'MAWB', alignRight: false },
  { id: 'hawb', label: 'HAWB', alignRight: false },
  { id: 'date', label: 'DATE', alignRight: false },
  { id: 'acctNoName', label: 'ACCOUNT NO./COMPANY NAME', alignRight: false },
  { id: 'dest', label: 'DEST', alignRight: false },
  { id: 'prodCode', label: 'PRODUCT CODE', alignRight: false },
  { id: 'weight', label: 'WEIGHT', alignRight: true },
  { id: 'iata', label: 'IATA', alignRight: true },
  { id: 'selling', label: 'SELLING', alignRight: true },
  { id: 'cost', label: 'COST', alignRight: true },
  { id: 'net', label: 'NET', alignRight: true },
  { id: 'mode', label: 'MODE', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_detail) => _detail.arrivalDate.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Inquiry() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('mawb');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [inquiryData, setInquiryData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [hawb, setHAWB] = useState('');

  const schemaGenerate = Yup.object().shape({
    hawb: Yup.string().nullable().required('HAWB is required')
  });

  const formikGenerate = useFormik({
    initialValues: {
      hawb: ''
    },
    validationSchema: schemaGenerate,
    onSubmit: () => {
      inquireByHAWB();
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setSubmitting
  } = formikGenerate;

  const inquireByHAWB = () => {
    setLoading(true);
    setSubmitting(true);

    const data = {
      hawb: hawb
    };

    InquiryService.inquireByHAWB(data)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        if (data.responseCode === 200) {
          setInquiryData(data.responseData);
          showAlert('success', data.responseMessage);
          setSubmitting(false);
          setLoading(false);
        } else {
          showAlert('error', data.responseMessage);
          setSubmitting(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = inquiryData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - inquiryData.length) : 0;

  const filteredDetails = applySortFilter(inquiryData, getComparator(order, orderBy), filterName);

  const isDetailNotFound = filteredDetails.length === 0;

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <>
      <ThreeCircles
        height="100"
        width="100"
        color="#ed1c24"
        wrapperStyle={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '99999',
          textAlign: 'center'
        }}
        wrapperClass=""
        visible={loading}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
      <Page title={`Inquiry | ${process.env.REACT_APP_APP_NAME}`}>
        <Container>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={hideAlert}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </MuiAlert>
            </Snackbar>
          </Stack>
          <Card className="card-upload">
            <FormikProvider value={formikGenerate}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                    <TextField
                      id="hawb"
                      name="hawb"
                      label="HAWB"
                      type="text"
                      value={hawb}
                      inputProps={{ maxLength: 20, style: { padding: '13.5px 14px' } }}
                      onChange={(e) => {
                        setHAWB(e.target.value);
                        setFieldValue('hawb', e.target.value);
                      }}
                      error={Boolean(touched.hawb && errors.hawb)}
                      helperText={touched.hawb && errors.hawb}
                    />

                    <LoadingButton
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={isSubmitting}
                      sx={{ maxWidth: '200px', alignSelf: 'flex-start' }}
                    >
                      Submit
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Form>
            </FormikProvider>
          </Card>

          <Card>
            {/* <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={inquiryData.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredDetails
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, i) => {
                        const {
                          mawb,
                          hawb,
                          date,
                          account,
                          dest,
                          prod,
                          weight,
                          iata,
                          selling,
                          cost,
                          net,
                          mode,
                          source
                        } = row;
                        const isItemSelected = selected.indexOf(mawb) !== -1;

                        return (
                          <TableRow
                            hover
                            key={i}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell align="left">{mawb}</TableCell>
                            <TableCell align="left">{hawb}</TableCell>
                            <TableCell align="left">{moment(date).format('yyyy-MM-DD')}</TableCell>
                            <TableCell align="left">{account}</TableCell>
                            <TableCell align="left">{dest}</TableCell>
                            <TableCell align="left">{prod}</TableCell>
                            <TableCell align="right">
                              {parseFloat(weight)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(iata)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(selling)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(cost)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(net)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </TableCell>
                            <TableCell align="left">{mode}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                  {inquiryData.length > 0 && isDetailNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={inquiryData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
