import React, { useEffect, useState, forwardRef } from 'react';
import { filter } from 'lodash';
// material
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Box,
  Stack,
  Snackbar,
  Alert,
  Link
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/_dashboard/table';
//
import CustomizeDestiRatesBlueprintUploaderService from '../../services/file-maintenance/CustomizeDestiRatesBlueprintUploader.service';
import UtilsService from '../../services/accounts-detail-uploader/Utils.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const moment = require('moment');

const TABLE_HEAD = [
  { id: 'account_no', label: 'Account No.', alignRight: false },
  { id: 'country_code', label: 'Country Code', alignRight: false },
  { id: 'product_code', label: 'Product Code', alignRight: false },
  { id: 'wt_kg_0_50', label: '0.50', alignRight: false },
  { id: 'wt_kg_1_00', label: '1.00', alignRight: false },
  { id: 'wt_kg_1_50', label: '1.50', alignRight: false },
  { id: 'wt_kg_2_00', label: '2.00', alignRight: false },
  { id: 'wt_kg_2_50', label: '2.50', alignRight: false },
  { id: 'wt_kg_3_00', label: '3.00', alignRight: false },
  { id: 'wt_kg_3_50', label: '3.50', alignRight: false },
  { id: 'wt_kg_4_00', label: '4.00', alignRight: false },
  { id: 'wt_kg_4_50', label: '4.50', alignRight: false },
  { id: 'wt_kg_5_00', label: '5.00', alignRight: false },
  { id: 'wt_kg_5_50', label: '5.50', alignRight: false },
  { id: 'wt_kg_6_00', label: '6.00', alignRight: false },
  { id: 'wt_kg_6_50', label: '6.50', alignRight: false },
  { id: 'wt_kg_7_00', label: '7.00', alignRight: false },
  { id: 'wt_kg_7_50', label: '7.50', alignRight: false },
  { id: 'wt_kg_8_00', label: '8.00', alignRight: false },
  { id: 'wt_kg_8_50', label: '8.50', alignRight: false },
  { id: 'wt_kg_9_00', label: '9.00', alignRight: false },
  { id: 'wt_kg_9_50', label: '9.50', alignRight: false },
  { id: 'wt_kg_10_00', label: '10.00', alignRight: false },
  { id: 'wt_kg_10_50', label: '10.50', alignRight: false },
  { id: 'wt_kg_11_00', label: '11.00', alignRight: false },
  { id: 'wt_kg_11_50', label: '11.50', alignRight: false },
  { id: 'wt_kg_12_00', label: '12.00', alignRight: false },
  { id: 'wt_kg_12_50', label: '12.50', alignRight: false },
  { id: 'wt_kg_13_00', label: '13.00', alignRight: false },
  { id: 'wt_kg_13_50', label: '13.50', alignRight: false },
  { id: 'wt_kg_14_00', label: '14.00', alignRight: false },
  { id: 'wt_kg_14_50', label: '14.50', alignRight: false },
  { id: 'wt_kg_15_00', label: '15.00', alignRight: false },
  { id: 'wt_kg_15_50', label: '15.50', alignRight: false },
  { id: 'wt_kg_16_00', label: '16.00', alignRight: false },
  { id: 'wt_kg_16_50', label: '16.50', alignRight: false },
  { id: 'wt_kg_17_00', label: '17.00', alignRight: false },
  { id: 'wt_kg_17_50', label: '17.50', alignRight: false },
  { id: 'wt_kg_18_00', label: '18.00', alignRight: false },
  { id: 'wt_kg_18_50', label: '18.50', alignRight: false },
  { id: 'wt_kg_19_00', label: '19.00', alignRight: false },
  { id: 'wt_kg_19_50', label: '19.50', alignRight: false },
  { id: 'wt_kg_20_00', label: '20.00', alignRight: false },
  { id: 'wt_kg_ovr_20', label: 'Over 20kgs', alignRight: false },
  { id: 'fsc', label: 'FSC', alignRight: false },
  { id: 'efdate_from', label: 'Effective Date (From)', alignRight: true },
  { id: 'efdate_to', label: 'Effective Date (To)', alignRight: true },
  { id: 'statusMessage', label: 'STATUS', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_detail) => _detail.mawb.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomizeDestiRatesBlueprintUploader() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('account_no');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [uploadedData, setUploadedData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (selectedFiles && selectedFiles.length > 0) {
      upload();
    }
  }, [selectedFiles]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const downloadTemplate = () => {
    UtilsService.getUploadTemplate('Customize_Desti_Rates_Blueprint')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const link = document.createElement('a');
          link.href = data.responseData;
          link.setAttribute('download', 'Customize_Desti_Rates_Blueprint_Temaplate.xlsx');
          document.body.appendChild(link);
          link.click();

          //showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showAlert('error', 'Could not download template!');
      });
  };

  const downloadLastUploaded = () => {
    UtilsService.getLastUploadedFile('Customize_Desti_Rates_Blueprint')
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const link = document.createElement('a');
          link.href = data.responseData;
          link.setAttribute('download', 'Customize_Desti_Rates_Blueprint.xlsx');
          document.body.appendChild(link);
          link.click();

          //showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showAlert('error', 'Could not Download Last Uploaded File file!');
      });
  };

  const download = () => {
    setDownloading(true);

    CustomizeDestiRatesBlueprintUploaderService.download()
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        console.log(data);

        if (data.responseCode === 200) {
          const outputFilename = `Customize_Desti_Rates_Blueprint.xls`;

          const byteCharacters = atob(data.responseData.excel);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          console.log(byteArray);

          const blob = await new Blob([byteArray], { type: 'application/excel' });
          const url = await window.URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();

          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }

        setDownloading(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        setDownloading(false);
      });
  };

  const upload = () => {
    const currentFile = selectedFiles[0];
    setProgress(0);
    setUploadedData([]);
    setCurrentFile(currentFile);
    setUploading(true);

    CustomizeDestiRatesBlueprintUploaderService.upload(currentFile, (event) => {
      setProgress(Math.round((90 * event.loaded) / event.total));
    })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          setUploadedData(data.responseData);
          showAlert('success', data.responseMessage);
          setProgress(100);
        } else {
          setUploadedData([]);
          showAlert('error', data.responseMessage);
          setProgress(100);
        }

        setUploading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setProgress(0);
        showAlert('error', 'Could not upload the file!');
        setCurrentFile(undefined);

        setUploading(false);
      });

    document.getElementById('btn-upload').value = '';
    setSelectedFiles(undefined);
  };

  const uploadAgain = () => {
    setProgress(0);
    setUploadedData([]);
    setCurrentFile(undefined);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = uploadedData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - uploadedData.length) : 0;

  const filteredDetails = applySortFilter(uploadedData, getComparator(order, orderBy), filterName);

  const isDetailNotFound = filteredDetails.length === 0;

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Page title={`IATA Uploader | ${process.env.REACT_APP_APP_NAME}`}>
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={hideAlert}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>

        <Card className="card-download">
          <div className="card-download-container">
            <LoadingButton
              className="btn-download"
              loading={downloading}
              variant="outlined"
              component="span"
              onClick={download}
            >
              Download Desti Rates Blueprint
            </LoadingButton>
          </div>
        </Card>

        <Card className="card-upload">
          <div className="card-upload-container">
            {uploading ? (
              <LoadingButton className="btn-choose" loading variant="outlined" component="span">
                Upload
              </LoadingButton>
            ) : !currentFile ? (
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={selectFile}
                />
                {/* <span>Drop .xlsx here or</span>  */}
                <Button className="btn-choose" variant="outlined" component="span">
                  Choose File
                </Button>
              </label>
            ) : (
              <Button
                className="btn-again"
                variant="outlined"
                component="span"
                onClick={uploadAgain}
              >
                Upload Again
              </Button>
            )}
            {currentFile && (
              <Box className="mb25" display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box minWidth={35}>
                  <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                </Box>
              </Box>
            )}
            <Box width="100%" mr={1} mt={3}>
              <Link
                className="download-template"
                component="button"
                variant="body2"
                onClick={downloadTemplate}
              >
                Download Template
              </Link>
              {' or '}
              <Link
                className="download-template"
                component="button"
                variant="body2"
                onClick={downloadLastUploaded}
              >
                Download Last Uploaded File
              </Link>
            </Box>
          </div>
        </Card>

        <Card>
          <ListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={uploadedData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredDetails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const {
                        account_no,
                        country_code,
                        product_code,
                        wt_kg_0_50,
                        wt_kg_1_00,
                        wt_kg_1_50,
                        wt_kg_2_00,
                        wt_kg_2_50,
                        wt_kg_3_00,
                        wt_kg_3_50,
                        wt_kg_4_00,
                        wt_kg_4_50,
                        wt_kg_5_00,
                        wt_kg_5_50,
                        wt_kg_6_00,
                        wt_kg_6_50,
                        wt_kg_7_00,
                        wt_kg_7_50,
                        wt_kg_8_00,
                        wt_kg_8_50,
                        wt_kg_9_00,
                        wt_kg_9_50,
                        wt_kg_10_00,
                        wt_kg_10_50,
                        wt_kg_11_00,
                        wt_kg_11_50,
                        wt_kg_12_00,
                        wt_kg_12_50,
                        wt_kg_13_00,
                        wt_kg_13_50,
                        wt_kg_14_00,
                        wt_kg_14_50,
                        wt_kg_15_00,
                        wt_kg_15_50,
                        wt_kg_16_00,
                        wt_kg_16_50,
                        wt_kg_17_00,
                        wt_kg_17_50,
                        wt_kg_18_00,
                        wt_kg_18_50,
                        wt_kg_19_00,
                        wt_kg_19_50,
                        wt_kg_20_00,
                        wt_kg_ovr_20,
                        fsc,
                        efdate_from,
                        efdate_to,
                        statusCode,
                        statusMessage
                      } = row;
                      const isItemSelected = selected.indexOf(account_no) !== -1;

                      return (
                        <TableRow
                          hover
                          key={i}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{account_no}</TableCell>
                          <TableCell align="left">{country_code}</TableCell>
                          <TableCell align="left">{product_code}</TableCell>
                          <TableCell align="left">{wt_kg_0_50}</TableCell>
                          <TableCell align="left">{wt_kg_1_00}</TableCell>
                          <TableCell align="left">{wt_kg_1_50}</TableCell>
                          <TableCell align="left">{wt_kg_2_00}</TableCell>
                          <TableCell align="left">{wt_kg_2_50}</TableCell>
                          <TableCell align="left">{wt_kg_3_00}</TableCell>
                          <TableCell align="left">{wt_kg_3_50}</TableCell>
                          <TableCell align="left">{wt_kg_4_00}</TableCell>
                          <TableCell align="left">{wt_kg_4_50}</TableCell>
                          <TableCell align="left">{wt_kg_5_00}</TableCell>
                          <TableCell align="left">{wt_kg_5_50}</TableCell>
                          <TableCell align="left">{wt_kg_6_00}</TableCell>
                          <TableCell align="left">{wt_kg_6_50}</TableCell>
                          <TableCell align="left">{wt_kg_7_00}</TableCell>
                          <TableCell align="left">{wt_kg_7_50}</TableCell>
                          <TableCell align="left">{wt_kg_8_00}</TableCell>
                          <TableCell align="left">{wt_kg_8_50}</TableCell>
                          <TableCell align="left">{wt_kg_9_00}</TableCell>
                          <TableCell align="left">{wt_kg_9_50}</TableCell>
                          <TableCell align="left">{wt_kg_10_00}</TableCell>
                          <TableCell align="left">{wt_kg_10_50}</TableCell>
                          <TableCell align="left">{wt_kg_11_00}</TableCell>
                          <TableCell align="left">{wt_kg_11_50}</TableCell>
                          <TableCell align="left">{wt_kg_12_00}</TableCell>
                          <TableCell align="left">{wt_kg_12_50}</TableCell>
                          <TableCell align="left">{wt_kg_13_00}</TableCell>
                          <TableCell align="left">{wt_kg_13_50}</TableCell>
                          <TableCell align="left">{wt_kg_14_00}</TableCell>
                          <TableCell align="left">{wt_kg_14_50}</TableCell>
                          <TableCell align="left">{wt_kg_15_00}</TableCell>
                          <TableCell align="left">{wt_kg_15_50}</TableCell>
                          <TableCell align="left">{wt_kg_16_00}</TableCell>
                          <TableCell align="left">{wt_kg_16_50}</TableCell>
                          <TableCell align="left">{wt_kg_17_00}</TableCell>
                          <TableCell align="left">{wt_kg_17_50}</TableCell>
                          <TableCell align="left">{wt_kg_18_00}</TableCell>
                          <TableCell align="left">{wt_kg_18_50}</TableCell>
                          <TableCell align="left">{wt_kg_19_00}</TableCell>
                          <TableCell align="left">{wt_kg_19_50}</TableCell>
                          <TableCell align="left">{wt_kg_20_00}</TableCell>
                          <TableCell align="left">{wt_kg_ovr_20}</TableCell>
                          <TableCell align="left">{fsc}</TableCell>
                          <TableCell align="right">{efdate_from}</TableCell>
                          <TableCell align="right">{efdate_to}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(statusCode !== '200' && 'error') || 'success'}
                            >
                              {statusMessage}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={48} />
                    </TableRow>
                  )}
                </TableBody>
                {uploadedData.length > 0 && isDetailNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={48} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={uploadedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
