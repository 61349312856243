import * as Yup from 'yup';
import React, { useEffect, useState, forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner';
import moment from 'moment';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Container,
  Autocomplete,
  Paper,
  Snackbar,
  Alert,
  Box,
  Card,
  CardActions,
  CardContent
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
// components
import Page from '../../components/Page';
//
import GenerateSOAService from '../../services/billing-and-invoicing/GenerateSOA.service';

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function GenerateSOA() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [soaMonth, setSOAMonth] = useState(new Date());
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [customersList, setCustomersList] = useState([]);
  const [customersListLoaded, setCustomersListLoaded] = useState(false);

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    setLoading(true);
    GenerateSOAService.getCustomerForSOA()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setCustomersList(data.responseData);
        setCustomersListLoaded(true);
        setLoading(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        setLoading(false);
      });
  }, []);

  const RegisterSchema = Yup.object().shape({
    customer: Yup.object().nullable().required('Customer is required'),
    soaMonth: Yup.string().required('This field is required')
  });

  const formik = useFormik({
    initialValues: {
      customer: null,
      soaMonth: new Date()
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      generateSOA();
      setSubmitting(false);
      // console.log(invDate);
      // if (invDate == 'Invalid Date') {
      //   touched.customer = true;
      //   errors.customer = 'Invalid invoice date';
      // } else {
      //   console.log(customer);
      //   console.log(value);
      //   // navigate('/dashboard', { replace: true });
      //   setSubmitting(false);
      // }
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setSubmitting
  } = formik;

  const generateSOA = () => {
    if (customer === null) {
      showAlert('error', 'Invalid customer');
    } else {
      setLoading(true);

      const data = {
        accountNo: customer.accountNo,
        source: customer.source
      };

      let urlFinal = '';

      GenerateSOAService.generateSOA(data)
        .then((response) => {
          return response.data;
        })
        .then(async (data) => {
          console.log(data);

          if (data.responseCode === 200) {
            const byteCharacters = atob(data.responseData.pdf);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            console.log(byteArray);

            const blob = await new Blob([byteArray], { type: 'application/pdf' });
            const url = await window.URL.createObjectURL(blob);
            urlFinal = url;

            if (urlFinal !== undefined && urlFinal !== '') {
              window.open(urlFinal);
            }

            showAlert('success', data.responseMessage);
          } else {
            showAlert('error', data.responseMessage);
          }
          setLoading(false);
        })
        .catch((error) => {
          showAlert('error', error.message);
          setLoading(false);
        });
    }
  };

  console.log(soaMonth);

  return (
    <>
      <ThreeCircles
        height="100"
        width="100"
        color="#ed1c24"
        wrapperStyle={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '99999',
          textAlign: 'center'
        }}
        wrapperClass=""
        visible={loading}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
      <Page title={`Generate Statmement Of Account | ${process.env.REACT_APP_APP_NAME}`}>
        <Container>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={hideAlert}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </MuiAlert>
            </Snackbar>
          </Stack>
        </Container>
        <Container maxWidth="md">
          <Card sx={{ minWidth: 275 }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <CardContent>
                  <Stack spacing={2}>
                    <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} spacing={2}>
                      <Autocomplete
                        fullWidth
                        disabled={!customersListLoaded}
                        options={customersList}
                        noOptionsText="No record found"
                        groupBy={(option) => option.source}
                        getOptionLabel={(option) => option.companyName}
                        onChange={(e, value) => {
                          setCustomer(value);
                          setFieldValue('customer', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer"
                            name="customer"
                            {...getFieldProps('customer')}
                            error={Boolean(touched.customer && errors.customer)}
                            helperText={touched.customer && errors.customer}
                          />
                        )}
                      />
                    </Stack>
                    {/* <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker 
                          views={['month', 'year']}
                          value={soaMonth}
                          onChange={(value) => {
                            console.log(value);
                            setSOAMonth(value !== null ? value : '');
                            setFieldValue('soaMonth', value !== null ? value : '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="For the month of"
                              name="soaMonth"
                              {...getFieldProps('soaMonth')}
                              error={Boolean(touched.soaMonth && errors.soaMonth)}
                              helperText={touched.soaMonth && errors.soaMonth}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack> */}
                  </Stack>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="outlined"
                    loading={isSubmitting}
                    sx={{ maxWidth: '200px', alignSelf: 'center' }}
                  >
                    Generate SOA
                  </LoadingButton>
                </CardActions>
              </Form>
            </FormikProvider>
          </Card>
        </Container>
      </Page>
    </>
  );
}
