import http from '../../http-common';

class GenerateInvoiceService {
  getCustomerForInvoice() {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.get('/api/billingAndInvoicing/getCustomerForInvoice', config);
  }

  getOEXDepartments() {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.get('/api/billingAndInvoicing/getOEXDepartments', config);
  }

  checkInvoiceNoIfExist(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.post('/api/billingAndInvoicing/checkInvoiceNoIfExist', data, config);
  }

  generateInvoice(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.post('/api/billingAndInvoicing/generateInvoice', data, config);
  }

  searchInvoice(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.post('/api/billingAndInvoicing/searchInvoice', data, config);
  }

  searchCustomerInvoice(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.post('/api/billingAndInvoicing/searchCustomerInvoice', data, config);
  }

  searchInvoiceByDepartment(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.post('/api/billingAndInvoicing/searchInvoiceByDepartment', data, config);
  }

  getInvMiscFees(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.post('/api/billingAndInvoicing/getInvMiscFees', data, config);
  }

  searchInvoiceData(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.post('/api/billingAndInvoicing/searchInvoiceData', data, config);
  }

  updateInvoiceDate(data) {
    const config = {
      headers: {
        Authorization: `Bearer ${global.userData.token}`
      },
      responseType: 'application/json'
    };

    return http.post('/api/billingAndInvoicing/updateInvoiceDate', data, config);
  }
}

export default new GenerateInvoiceService();
