import http from '../../http-common';

class DetailsUploaderService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    const config = {
      headers: { 
        'Authorization': `Bearer ${global.userData.token}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'application/json',
      onUploadProgress,
    };

    return http.post("/api/billingDetails/upload", formData, config);
  }
}

export default new DetailsUploaderService();
